import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  TableContainer,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  VStack,
  HStack,
  Text,
  useToast,
  Divider,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Flex,
  useColorModeValue,
  Container,
  InputGroup,
  Input,
  InputLeftElement,
  Tag,
  TagLabel,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Badge,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Icon,
} from '@chakra-ui/react';
import { FiTrash2, FiEye, FiDownload, FiFilter, FiChevronDown, FiChevronUp, FiSearch, FiCalendar, FiMoreVertical, FiAlertCircle, FiUser, FiMapPin } from 'react-icons/fi';
import { Document, Page, Text as PDFText, View, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import DecesFilesList from './DecesFilesList';
import { motion } from 'framer-motion';

// Enregistrer les polices personnalisées
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionButton = motion(Button);

const DecesList = () => {
  const [decesData, setDecesData] = useState({
    deces: [],
    isLoading: true,
    error: null,
  });
  const [selectedDeces, setSelectedDeces] = useState(null);
  const [selectedDecesFiles, setSelectedDecesFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterYear, setFilterYear] = useState('');
  const toast = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [decesToDelete, setDecesToDelete] = useState(null);
  const cancelRef = useRef();

  // Enhanced color mode values
  const bgGradient = useColorModeValue(
    'linear(to-b, blue.50, white)',
    'linear(to-b, gray.900, gray.800)'
  );
  const boxBg = useColorModeValue('white', 'gray.800');
  const headingColor = useColorModeValue('gray.800', 'white');
  const tableBg = useColorModeValue('white', 'gray.700');
  const theadBg = useColorModeValue('gray.50', 'gray.600');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');
  const modalBg = useColorModeValue('white', 'gray.800');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const statBg = useColorModeValue('blue.50', 'gray.700');
  const iconColor = useColorModeValue('blue.500', 'blue.300');

  // Animation variants
  const containerAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  // Stats calculations
  const stats = useMemo(() => {
    if (!decesData.deces.length) return null;

    const currentYear = new Date().getFullYear();
    const thisYearDeces = decesData.deces.filter(
      d => new Date(d.dateDecesLieu).getFullYear() === currentYear
    );
    
    const totalThisYear = thisYearDeces.reduce((acc, curr) => acc + curr.total, 0);
    const avgThisYear = totalThisYear / (thisYearDeces.length || 1);
    
    const lastYearDeces = decesData.deces.filter(
      d => new Date(d.dateDecesLieu).getFullYear() === currentYear - 1
    );
    const totalLastYear = lastYearDeces.reduce((acc, curr) => acc + curr.total, 0);
    
    const percentChange = ((totalThisYear - totalLastYear) / totalLastYear) * 100;

    return {
      count: thisYearDeces.length,
      total: totalThisYear,
      average: avgThisYear,
      percentChange
    };
  }, [decesData.deces]);

  useEffect(() => {
    fetchDeces();
  }, []);

  const fetchDeces = async () => {
    try {
      setDecesData({ ...decesData, isLoading: true });
      const response = await axios.get('https://app.falconmarketing.fr:3004/deces');
      if (response.data && Array.isArray(response.data.deces)) {
        setDecesData({ deces: response.data.deces, isLoading: false, error: null });
      } else {
        throw new Error("La réponse du serveur n'est pas valide.");
      }
    } catch (error) {
      setDecesData({ deces: [], isLoading: false, error });
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les décès.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(decesData.deces);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Deces');
    XLSX.writeFile(workbook, 'deces.xlsx');
  };

  const handleViewDetails = async (deces) => {
    try {
      const response = await axios.get(`https://app.falconmarketing.fr:3004/deces/${deces.id}/files`);
      setSelectedDecesFiles(response.data.files);
      setSelectedDeces(deces);
      onOpen();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les fichiers.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSortChange = () => {
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const handleFilterChange = (event) => {
    setFilterYear(event.target.value);
  };

  const uniqueYears = useMemo(() => {
    return [...new Set(decesData.deces.map((d) => new Date(d.dateDecesLieu).getFullYear()))].sort(
      (a, b) => b - a
    );
  }, [decesData.deces]);

  const sortedAndFilteredDeces = useMemo(() => {
    let sortedDeces = [...decesData.deces].sort((a, b) => {
      const dateA = new Date(a.dateDecesLieu);
      const dateB = new Date(b.dateDecesLieu);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    if (filterYear) {
      sortedDeces = sortedDeces.filter(
        (d) => new Date(d.dateDecesLieu).getFullYear().toString() === filterYear
      );
    }
    return sortedDeces;
  }, [decesData.deces, sortOrder, filterYear]);

  const handleDelete = (deces) => {
    setDecesToDelete(deces);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`https://app.falconmarketing.fr:3004/deces/${decesToDelete.id}`);
      if (response.status === 200) {
        toast({
          title: 'Succès',
          description: 'Le décès a été supprimé avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchDeces();
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le décès.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
      setDecesToDelete(null);
    }
  };

  const handleFileDeleted = async (decesId) => {
    try {
      // Rafraîchir la liste des fichiers
      const response = await axios.get(`https://app.falconmarketing.fr:3004/deces/${selectedDeces.id}/files`);
      setSelectedDecesFiles(response.data.files);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de rafraîchir la liste des fichiers.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Styles pour le PDF
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 40,
      fontFamily: 'Roboto',
    },
    header: {
      marginBottom: 30,
      borderBottomWidth: 2,
      borderBottomColor: '#1a365d',
      paddingBottom: 10,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      color: '#1a365d',
      fontWeight: 700,
      marginBottom: 10,
    },
    subtitle: {
      fontSize: 12,
      textAlign: 'center',
      color: '#4a5568',
      marginBottom: 20,
    },
    section: {
      marginBottom: 20,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: '#2c5282',
      marginBottom: 10,
      backgroundColor: '#EDF2F7',
      padding: 8,
      borderRadius: 4,
    },
    row: {
      flexDirection: 'row',
      marginBottom: 8,
      paddingBottom: 4,
      borderBottomWidth: 1,
      borderBottomColor: '#E2E8F0',
    },
    label: {
      width: '40%',
      fontSize: 12,
      color: '#4a5568',
      fontWeight: 500,
    },
    value: {
      width: '60%',
      fontSize: 12,
      color: '#1a202c',
    },
    expensesGrid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 20,
    },
    expenseItem: {
      width: '50%',
      flexDirection: 'row',
      marginBottom: 8,
      paddingRight: 10,
    },
    totalSection: {
      marginTop: 20,
      borderTopWidth: 2,
      borderTopColor: '#E2E8F0',
      paddingTop: 10,
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    totalLabel: {
      fontSize: 14,
      fontWeight: 500,
      color: '#2c5282',
      marginRight: 20,
    },
    totalValue: {
      fontSize: 14,
      fontWeight: 700,
      color: '#1a365d',
    },
    observations: {
      marginTop: 20,
      padding: 10,
      backgroundColor: '#F7FAFC',
      borderRadius: 4,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: '#718096',
      fontSize: 10,
      paddingTop: 10,
      borderTopWidth: 1,
      borderTopColor: '#E2E8F0',
    },
  });

  const MyDocument = ({ deces }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* En-tête */}
        <View style={styles.header}>
          <PDFText style={styles.title}>Fiche de décès</PDFText>
          <PDFText style={styles.subtitle}>Association El Mektoub</PDFText>
        </View>

        {/* Informations générales */}
        <View style={styles.section}>
          <PDFText style={styles.sectionTitle}>Informations générales</PDFText>
          <View style={styles.row}>
            <PDFText style={styles.label}>Délégué</PDFText>
            <PDFText style={styles.value}>{deces.delegue}</PDFText>
          </View>
          <View style={styles.row}>
            <PDFText style={styles.label}>Nom du défunt</PDFText>
            <PDFText style={styles.value}>{deces.nomDefunt}</PDFText>
          </View>
          <View style={styles.row}>
            <PDFText style={styles.label}>Enfant</PDFText>
            <PDFText style={styles.value}>{deces.enfant ? 'Oui' : 'Non'}</PDFText>
          </View>
          <View style={styles.row}>
            <PDFText style={styles.label}>Date de décès</PDFText>
            <PDFText style={styles.value}>
              {new Date(deces.dateDecesLieu).toLocaleDateString('fr-FR')}
            </PDFText>
          </View>
        </View>

        {/* Lieux */}
        <View style={styles.section}>
          <PDFText style={styles.sectionTitle}>Lieux</PDFText>
          <View style={styles.row}>
            <PDFText style={styles.label}>Lieu de décès</PDFText>
            <PDFText style={styles.value}>{deces.lieuDeces}</PDFText>
          </View>
          {deces.lieuDeces2 && (
            <View style={styles.row}>
              <PDFText style={styles.label}>Lieu de décès (autre)</PDFText>
              <PDFText style={styles.value}>{deces.lieuDeces2}</PDFText>
            </View>
          )}
          <View style={styles.row}>
            <PDFText style={styles.label}>Lieu d'enterrement</PDFText>
            <PDFText style={styles.value}>{deces.lieuEnterrement}</PDFText>
          </View>
        </View>

        {/* Dépenses */}
        <View style={styles.section}>
          <PDFText style={styles.sectionTitle}>Dépenses</PDFText>
          <View style={styles.expensesGrid}>
            {[
              ['Funérarium', deces.funerarium],
              ['Toilette', deces.toilette],
              ['Pompes funèbres', deces.pompesFunebres],
              ["Billet d'accompagnement", deces.billetAccompagnement],
              ['Traitement', deces.traitement],
              ['Repas', deces.repas],
              ['Concession', deces.concession],
              ['Décès en Algérie', deces.decesAlgerie],
            ].map(([label, value]) => (
              <View style={styles.expenseItem} key={label}>
                <PDFText style={styles.label}>{label}</PDFText>
                <PDFText style={styles.value}>{value} €</PDFText>
              </View>
            ))}
          </View>
        </View>

        {/* Total */}
        <View style={styles.totalSection}>
          <View style={styles.totalRow}>
            <PDFText style={styles.totalLabel}>Total</PDFText>
            <PDFText style={styles.totalValue}>{deces.total} €</PDFText>
          </View>
          <View style={styles.totalRow}>
            <PDFText style={styles.totalLabel}>Montant restant</PDFText>
            <PDFText style={styles.totalValue}>{deces.montantRestant} €</PDFText>
          </View>
        </View>

        {/* Observations */}
        {deces.observations && (
          <View style={styles.observations}>
            <PDFText style={styles.sectionTitle}>Observations</PDFText>
            <PDFText style={styles.value}>{deces.observations}</PDFText>
          </View>
        )}

        {/* Pied de page */}
        <View style={styles.footer}>
          <PDFText>
            Association El Mektoub - Document généré le {new Date().toLocaleDateString('fr-FR')}
          </PDFText>
        </View>
      </Page>
    </Document>
  );

  return (
    <Box 
      minH="100vh"
      bgGradient={bgGradient}
      py={{ base: 4, md: 8 }}
      px={{ base: 4, md: 0 }}
      transition="all 0.2s"
    >
      <Container maxW="7xl">
        <MotionBox
          initial="hidden"
          animate="visible"
          variants={containerAnimation}
        >
          <VStack spacing={8}>
            {/* Header Section */}
            <MotionBox 
              w="full" 
              textAlign="center" 
              py={8}
              variants={itemAnimation}
            >
              <Heading 
                size="xl" 
                color={headingColor}
                fontWeight="bold"
                letterSpacing="tight"
                mb={3}
              >
                Liste des Décès
              </Heading>
              <Text 
                color={secondaryTextColor}
                fontSize="lg"
              >
                Gestion et suivi des dossiers
              </Text>
            </MotionBox>

            {/* Stats Section */}
            {stats && (
              <MotionBox
                variants={itemAnimation}
                w="full"
              >
                <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6}>
                  <Stat
                    px={6}
                    py={4}
                    bg={statBg}
                    borderRadius="lg"
                    boxShadow="sm"
                  >
                    <StatLabel color={secondaryTextColor}>Nombre de décès cette année</StatLabel>
                    <StatNumber color={headingColor}>{stats.count}</StatNumber>
                  </Stat>
                  <Stat
                    px={6}
                    py={4}
                    bg={statBg}
                    borderRadius="lg"
                    boxShadow="sm"
                  >
                    <StatLabel color={secondaryTextColor}>Total des dépenses</StatLabel>
                    <StatNumber color={headingColor}>{stats.total.toLocaleString()} €</StatNumber>
                    <StatHelpText>
                      <StatArrow type={stats.percentChange > 0 ? 'increase' : 'decrease'} />
                      {Math.abs(stats.percentChange).toFixed(1)}% par rapport à l'an dernier
                    </StatHelpText>
                  </Stat>
                  <Stat
                    px={6}
                    py={4}
                    bg={statBg}
                    borderRadius="lg"
                    boxShadow="sm"
                  >
                    <StatLabel color={secondaryTextColor}>Moyenne par décès</StatLabel>
                    <StatNumber color={headingColor}>{stats.average.toLocaleString()} €</StatNumber>
                  </Stat>
                  <Stat
                    px={6}
                    py={4}
                    bg={statBg}
                    borderRadius="lg"
                    boxShadow="sm"
                  >
                    <StatLabel color={secondaryTextColor}>Fichiers joints</StatLabel>
                    <StatNumber color={headingColor}>
                      {decesData.deces.reduce((acc, curr) => acc + (selectedDecesFiles.length || 0), 0)}
                    </StatNumber>
                  </Stat>
                </SimpleGrid>
              </MotionBox>
            )}

            {/* Controls Section */}
            <MotionBox
              variants={itemAnimation}
              w="full"
              bg={boxBg}
              p={6}
              borderRadius="xl"
              boxShadow="lg"
              border="1px"
              borderColor={borderColor}
            >
              <Flex 
                direction={{ base: "column", md: "row" }} 
                justify="space-between" 
                align={{ base: "stretch", md: "center" }}
                gap={4}
              >
                <HStack spacing={4} flex={{ base: "auto", md: 1 }}>
                  <InputGroup maxW="300px">
                    <InputLeftElement pointerEvents="none">
                      <FiSearch color={iconColor} />
                    </InputLeftElement>
                    <Input 
                      placeholder="Rechercher..."
                      bg={boxBg}
                      _hover={{ bg: hoverBg }}
                    />
                  </InputGroup>
                  <Select
                    placeholder="Année"
                    value={filterYear}
                    onChange={handleFilterChange}
                    maxW="150px"
                    bg={boxBg}
                    _hover={{ bg: hoverBg }}
                    icon={<FiCalendar />}
                  >
                    {uniqueYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </HStack>
                <HStack spacing={4}>
                  <MotionButton
                    onClick={handleSortChange}
                    colorScheme="blue"
                    variant="outline"
                    leftIcon={sortOrder === 'asc' ? <FiChevronUp /> : <FiChevronDown />}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Trier par date
                  </MotionButton>
                  <MotionButton
                    onClick={exportToExcel}
                    colorScheme="green"
                    leftIcon={<FiDownload />}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Exporter
                  </MotionButton>
                </HStack>
              </Flex>
            </MotionBox>

            {/* Table Section */}
            <MotionBox
              variants={itemAnimation}
              w="full"
              bg={boxBg}
              borderRadius="xl"
              boxShadow="lg"
              border="1px"
              borderColor={borderColor}
              overflow="hidden"
            >
              {decesData.isLoading ? (
                <Flex justify="center" align="center" minH="400px">
                  <Spinner size="xl" color={iconColor} thickness="4px" />
                </Flex>
              ) : (
                <TableContainer>
                  <Table variant="simple">
                    <Thead bg={theadBg}>
                      <Tr>
                        <Th color={secondaryTextColor}>Délégué</Th>
                        <Th color={secondaryTextColor}>Nom du défunt</Th>
                        <Th color={secondaryTextColor}>Date</Th>
                        <Th color={secondaryTextColor}>Lieu</Th>
                        <Th isNumeric color={secondaryTextColor}>Total</Th>
                        <Th color={secondaryTextColor}>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortedAndFilteredDeces.map((deces) => (
                        <Tr 
                          key={deces.id}
                          _hover={{ bg: hoverBg }}
                          transition="background 0.2s"
                        >
                          <Td>
                            <HStack>
                              <Icon as={FiUser} color={iconColor} />
                              <Text color={textColor}>{deces.delegue}</Text>
                            </HStack>
                          </Td>
                          <Td>
                            <Text color={textColor} fontWeight="medium">
                              {deces.nomDefunt}
                              {deces.enfant && (
                                <Badge ml={2} colorScheme="purple" variant="subtle">
                                  Enfant
                                </Badge>
                              )}
                            </Text>
                          </Td>
                          <Td>
                            <HStack>
                              <Icon as={FiCalendar} color={iconColor} />
                              <Text color={textColor}>
                                {new Date(deces.dateDecesLieu).toLocaleDateString('fr-FR')}
                              </Text>
                            </HStack>
                          </Td>
                          <Td>
                            <HStack>
                              <Icon as={FiMapPin} color={iconColor} />
                              <Text color={textColor}>{deces.lieuEnterrement}</Text>
                            </HStack>
                          </Td>
                          <Td isNumeric>
                            <Text 
                              color={textColor} 
                              fontWeight="bold"
                              fontSize="lg"
                            >
                              {deces.total.toLocaleString()} €
                            </Text>
                          </Td>
                          <Td>
                            <HStack spacing={2}>
                              <Tooltip label="Voir les détails" hasArrow>
                                <MotionButton
                                  as={IconButton}
                                  icon={<FiEye />}
                                  colorScheme="blue"
                                  variant="ghost"
                                  onClick={() => handleViewDetails(deces)}
                                  whileHover={{ scale: 1.1 }}
                                  whileTap={{ scale: 0.9 }}
                                />
                              </Tooltip>
                              <Tooltip label="Télécharger PDF" hasArrow>
                                <PDFDownloadLink
                                  document={<MyDocument deces={deces} />}
                                  fileName={`deces_${deces.nomDefunt}.pdf`}
                                >
                                  {({ loading }) => (
                                    <MotionButton
                                      as={IconButton}
                                      icon={<FiDownload />}
                                      colorScheme="green"
                                      variant="ghost"
                                      isLoading={loading}
                                      whileHover={{ scale: 1.1 }}
                                      whileTap={{ scale: 0.9 }}
                                    />
                                  )}
                                </PDFDownloadLink>
                              </Tooltip>
                              <Tooltip label="Supprimer" hasArrow>
                                <MotionButton
                                  as={IconButton}
                                  icon={<FiTrash2 />}
                                  colorScheme="red"
                                  variant="ghost"
                                  onClick={() => handleDelete(deces)}
                                  whileHover={{ scale: 1.1 }}
                                  whileTap={{ scale: 0.9 }}
                                />
                              </Tooltip>
                            </HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </MotionBox>
          </VStack>
        </MotionBox>
      </Container>

      {/* Modal for Details - Enhanced */}
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size="xl" 
        scrollBehavior="inside"
        motionPreset="slideInBottom"
      >
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent bg={modalBg} borderRadius="xl" boxShadow="xl">
          <ModalHeader 
            borderBottomWidth="1px" 
            borderColor={borderColor}
            py={6}
          >
            <HStack>
              <Icon as={FiUser} color={iconColor} boxSize={5} />
              <Text color={headingColor}>Détails du décès</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton color={textColor} />
          <ModalBody py={6}>
            {selectedDeces && (
              <VStack align="stretch" spacing={6}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <Box>
                    <Text fontWeight="bold" color={textColor} mb={2}>Informations générales</Text>
                    <VStack align="stretch" spacing={4} bg={hoverBg} p={4} borderRadius="md">
                      <HStack justify="space-between">
                        <Text color={secondaryTextColor}>Délégué</Text>
                        <Text color={textColor}>{selectedDeces.delegue}</Text>
                      </HStack>
                      <HStack justify="space-between">
                        <Text color={secondaryTextColor}>Nom du défunt</Text>
                        <Text color={textColor}>{selectedDeces.nomDefunt}</Text>
                      </HStack>
                      <HStack justify="space-between">
                        <Text color={secondaryTextColor}>Date du décès</Text>
                        <Text color={textColor}>
                          {new Date(selectedDeces.dateDecesLieu).toLocaleDateString('fr-FR')}
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor} mb={2}>Localisation</Text>
                    <VStack align="stretch" spacing={4} bg={hoverBg} p={4} borderRadius="md">
                      <HStack justify="space-between">
                        <Text color={secondaryTextColor}>Lieu du décès</Text>
                        <Text color={textColor}>{selectedDeces.lieuDeces}</Text>
                      </HStack>
                      <HStack justify="space-between">
                        <Text color={secondaryTextColor}>Lieu d'enterrement</Text>
                        <Text color={textColor}>{selectedDeces.lieuEnterrement}</Text>
                      </HStack>
                      <HStack justify="space-between">
                        <Text color={secondaryTextColor}>Date d'inhumation</Text>
                        <Text color={textColor}>
                          {selectedDeces.dateInhumation
                            ? new Date(selectedDeces.dateInhumation).toLocaleDateString('fr-FR')
                            : 'Non spécifiée'}
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>
                </SimpleGrid>

                <Box>
                  <Text fontWeight="bold" color={textColor} mb={2}>Observations</Text>
                  <Box bg={hoverBg} p={4} borderRadius="md">
                    <Text color={textColor}>
                      {selectedDeces.observations || 'Aucune observation'}
                    </Text>
                  </Box>
                </Box>

                <Divider borderColor={borderColor} />

                <Box>
                  <Text fontWeight="bold" color={textColor} mb={4}>Pièces justificatives</Text>
                  <DecesFilesList 
                    files={selectedDecesFiles} 
                    onFileDeleted={() => handleFileDeleted(selectedDeces.id)}
                  />
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter 
            borderTopWidth="1px" 
            borderColor={borderColor}
            py={6}
          >
            <MotionButton 
              onClick={onClose}
              colorScheme="blue"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Fermer
            </MotionButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* AlertDialog for Delete - Enhanced */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay backdropFilter="blur(4px)">
          <AlertDialogContent bg={modalBg} borderRadius="xl" boxShadow="xl">
            <AlertDialogHeader 
              fontSize="lg" 
              fontWeight="bold" 
              color={headingColor}
              borderBottomWidth="1px"
              borderColor={borderColor}
              py={6}
            >
              <HStack>
                <Icon as={FiAlertCircle} color="red.500" boxSize={5} />
                <Text>Confirmer la suppression</Text>
              </HStack>
            </AlertDialogHeader>
            <AlertDialogBody py={6}>
              <VStack align="stretch" spacing={4}>
                <Text color={textColor}>
                  Êtes-vous sûr de vouloir supprimer ce décès ? Cette action est irréversible.
                </Text>
                {decesToDelete && (
                  <Box bg={hoverBg} p={4} borderRadius="md">
                    <Text color={secondaryTextColor}>
                      Décès de : <Text as="span" fontWeight="bold" color={textColor}>{decesToDelete.nomDefunt}</Text>
                    </Text>
                  </Box>
                )}
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter 
              py={6}
              borderTopWidth="1px"
              borderColor={borderColor}
            >
              <HStack spacing={3}>
                <MotionButton 
                  ref={cancelRef} 
                  onClick={() => setIsDeleteDialogOpen(false)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Annuler
                </MotionButton>
                <MotionButton 
                  colorScheme="red" 
                  onClick={confirmDelete}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Supprimer
                </MotionButton>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default DecesList;
