import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  useColorModeValue,
  Text,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  Icon,
  Badge,
  Button,
  useToast,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  HStack,
  VStack,
  Container,
  Heading,
  Divider,
  useBreakpointValue,
  Fade,
  SlideFade,
  ScaleFade,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  MdSearch,
  MdEdit,
  MdWarning,
  MdPersonAdd,
  MdFilterList,
  MdMoreVert,
  MdCheck,
  MdClose,
  MdRefresh,
} from "react-icons/md";

// Styled components using motion
const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionTr = motion(Tr);

const ChildrenManagement = () => {
  const [childrenData, setChildrenData] = useState({
    children: {
      valid: [],
      invalid: [],
      missing: []
    },
    stats: {
      total: 0,
      valid: 0,
      invalid: 0,
      missing: 0,
      adultsCount: 0
    },
    pagination: {
      total: 0,
      page: 1,
      limit: 10,
      totalPages: 1
    }
  });
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [ageFilter, setAgeFilter] = useState("all");
  const [selectedChild, setSelectedChild] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [activeTab, setActiveTab] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [childToTransfer, setChildToTransfer] = useState(null);
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
  const [isMultipleTransfer, setIsMultipleTransfer] = useState(false);
  const [transferCount, setTransferCount] = useState(0);
  const cancelRef = React.useRef();

  // Enhanced color modes for Apple-like aesthetics
  const bgGradient = useColorModeValue(
    "linear(to-b, gray.50, white)",
    "linear(to-b, gray.900, gray.800)"
  );
  const cardBg = useColorModeValue(
    "rgba(255, 255, 255, 0.8)",
    "rgba(23, 25, 35, 0.8)"
  );
  const glassBg = useColorModeValue(
    "rgba(255, 255, 255, 0.7)",
    "rgba(23, 25, 35, 0.7)"
  );
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textColor = useColorModeValue("gray.800", "white");
  const mutedTextColor = useColorModeValue("gray.600", "gray.400");
  const iconColor = useColorModeValue("gray.600", "gray.400");
  const hoverBg = useColorModeValue("gray.50", "whiteAlpha.100");
  const activeBg = useColorModeValue("gray.100", "whiteAlpha.200");

  // Responsive design
  const isMobile = useBreakpointValue({ base: true, md: false });
  const containerWidth = useBreakpointValue({
    base: "100%",
    md: "container.md",
    lg: "container.lg",
    xl: "container.xl",
  });

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3 },
    },
  };

  // Fonction pour calculer l'âge et les années restantes jusqu'à 25 ans
  const calculateAge = (birthDate) => {
    if (!birthDate) return { age: null, yearsTo25: null };
    
    const today = new Date();
    const [day, month, year] = birthDate.split('/').map(Number);
    const birth = new Date(year, month - 1, day);
    
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    
    const yearsTo25 = 25 - age;
    return { age, yearsTo25 };
  };

  // Récupération des données des enfants
  const fetchChildren = useCallback(async () => {
    try {
      const params = new URLSearchParams({
        page: currentPage.toString(),
        limit: pageSize.toString(),
        ageFilter: ageFilter,
        search: searchQuery,
        dateStatus: activeTab === 0 ? 'valid' : activeTab === 1 ? 'invalid' : activeTab === 2 ? 'missing' : 'all'
      });

      const response = await fetch(`https://app.falconmarketing.fr:3004/children?${params}`);
      if (!response.ok) throw new Error("Erreur lors de la récupération des données");
      
      const data = await response.json();
      
      // S'assurer que la structure est valide
      setChildrenData({
        children: {
          valid: Array.isArray(data.children?.valid) ? data.children.valid : [],
          invalid: Array.isArray(data.children?.invalid) ? data.children.invalid : [],
          missing: Array.isArray(data.children?.missing) ? data.children.missing : []
        },
        stats: {
          total: data.stats?.total || 0,
          valid: data.stats?.valid || 0,
          invalid: data.stats?.invalid || 0,
          missing: data.stats?.missing || 0,
          adultsCount: data.stats?.adultsCount || 0
        },
        pagination: {
          total: data.pagination?.total || 0,
          page: data.pagination?.page || 1,
          limit: data.pagination?.limit || 10,
          totalPages: data.pagination?.totalPages || 1
        }
      });
    } catch (error) {
      console.error("Erreur:", error);
      toast({
        title: "Erreur",
        description: "Impossible de charger la liste des enfants",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast, currentPage, pageSize, ageFilter, searchQuery, activeTab]);

  // Effet pour recharger les données lors des changements de filtres
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setCurrentPage(1); // Réinitialiser la page lors des changements de filtres
      fetchChildren();
    }, 300); // Délai de debounce pour la recherche

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, ageFilter]);

  // Effet pour recharger les données lors des changements de pagination
  useEffect(() => {
    fetchChildren();
  }, [currentPage, pageSize]);

  // Effet pour recharger les données lors des changements d'onglet
  useEffect(() => {
    setCurrentPage(1); // Réinitialiser la page lors du changement d'onglet
    fetchChildren();
  }, [activeTab]);

  // Gestionnaire de changement d'onglet
  const handleTabChange = (index) => {
    setActiveTab(index);
    // Réinitialiser les filtres
    setSearchQuery('');
    setAgeFilter('all');
    setCurrentPage(1);
  };

  // Gestionnaire de changement de filtre d'âge
  const handleAgeFilterChange = (value) => {
    setAgeFilter(value);
    setCurrentPage(1);
  };

  // Gestionnaire de recherche
  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };

  // Gestionnaire de changement de page
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Gestionnaire de changement de taille de page
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Réinitialiser à la première page lors du changement de taille
  };

  // Fonction pour filtrer les enfants
  const filterChildren = (children) => {
    if (!children || !Array.isArray(children)) {
      return [];
    }

    return children.filter(child => {
      if (!child) return false;

      const matchesSearch = 
        (child.name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
        (child.parentFullName?.toLowerCase() || '').includes(searchQuery.toLowerCase());

      if (!matchesSearch) return false;
      if (ageFilter === "all") return true;

      const { yearsTo25 } = calculateAge(child.birthDate);
      if (yearsTo25 === null) return false;
      
      switch (ageFilter) {
        case "over25":
          return yearsTo25 <= 0;
        case "critical":
          return yearsTo25 > 0 && yearsTo25 <= 1;
        case "warning":
          return yearsTo25 > 1 && yearsTo25 <= 3;
        case "normal":
          return yearsTo25 > 3;
        default:
          return true;
      }
    });
  };

  // Row animation variants
  const rowVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { duration: 0.3 }
    },
    hover: { 
      scale: 1.01,
      backgroundColor: useColorModeValue("#F7FAFC", "rgba(255, 255, 255, 0.08)"),
      transition: { duration: 0.2 }
    }
  };

  // Fonction pour formater une date
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    
    // Si la date est déjà au format JJ/MM/AAAA, on la retourne telle quelle
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
      return dateStr;
    }

    try {
      // Essayer de parser la date
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) return dateStr; // Si la date est invalide, retourner la chaîne originale

      // Formater au format JJ/MM/AAAA
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    } catch (error) {
      console.error('Erreur lors du formatage de la date:', error);
      return dateStr;
    }
  };

  // Mobile table row renderer
  const renderMobileRow = (child, dateStatus) => {
    const { age, yearsTo25 } = calculateAge(child.birthDate);
    const ageColor = 
      child.ageCategory === 'over25' ? 'red.500' :
      child.ageCategory === 'critical' ? 'orange.500' :
      child.ageCategory === 'warning' ? 'yellow.500' : 'green.500';

    const formattedDate = dateStatus === 'invalid' ? formatDate(child.birthDate) : child.birthDate;

    return (
      <MotionBox
        key={child.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
        bg={cardBg}
        p={4}
        borderRadius="lg"
        mb={3}
        border="1px solid"
        borderColor={borderColor}
        _hover={{ bg: hoverBg }}
      >
        <VStack align="stretch" spacing={3}>
          {/* Name and Status */}
          <Flex justify="space-between" align="center">
            <HStack spacing={3}>
              <Box
                w={2}
                h={2}
                borderRadius="full"
                bg={ageColor}
              />
              <Text fontWeight="medium">{child.name || 'N/A'}</Text>
            </HStack>
            <Badge
              colorScheme={
                child.dateStatus === 'valid' ? 'green' :
                child.dateStatus === 'invalid' ? 'orange' : 'red'
              }
              variant="subtle"
              fontSize="xs"
              px={2}
              borderRadius="full"
            >
              {child.dateStatus === 'valid' ? 'Valide' :
               child.dateStatus === 'invalid' ? 'À corriger' : 'Manquante'}
            </Badge>
          </Flex>

          {/* Parent */}
          <HStack justify="space-between">
            <Text fontSize="sm" color={mutedTextColor}>Parent</Text>
            <Text fontSize="sm">{child.parentFullName || 'N/A'}</Text>
          </HStack>

          {/* Birth Date */}
          <HStack justify="space-between">
            <Text fontSize="sm" color={mutedTextColor}>Date de naissance</Text>
            <Text fontSize="sm">{formattedDate || "Non spécifiée"}</Text>
          </HStack>

          {/* Age */}
          <HStack justify="space-between">
            <Text fontSize="sm" color={mutedTextColor}>Âge</Text>
            <VStack align="end" spacing={0}>
              <Text fontSize="sm" fontWeight="medium" color={ageColor}>
                {age !== null ? `${age} ans` : "N/A"}
              </Text>
              {yearsTo25 !== null && (
                <Text fontSize="xs" color={mutedTextColor}>
                  {yearsTo25 <= 0 
                    ? 'Plus de 25 ans'
                    : `${yearsTo25} an${yearsTo25 > 1 ? 's' : ''} avant 25 ans`}
                </Text>
              )}
            </VStack>
          </HStack>

          {/* Actions */}
          <HStack spacing={2} justify="flex-end">
            <IconButton
              size="sm"
              variant="ghost"
              colorScheme="gray"
              icon={<Icon as={MdEdit} />}
              onClick={() => {
                setSelectedChild({
                  ...child,
                  birthDate: formattedDate
                });
                onOpen();
              }}
              _hover={{ bg: hoverBg }}
              aria-label="Modifier"
            />
            <IconButton
              size="sm"
              variant="ghost"
              colorScheme="red"
              icon={<Icon as={MdPersonAdd} />}
              onClick={() => handleTransferSingleChild(child)}
              _hover={{ bg: hoverBg }}
              aria-label="Transférer en adhérent"
            />
            <Menu>
              <MenuButton
                as={IconButton}
                size="sm"
                variant="ghost"
                colorScheme="gray"
                icon={<Icon as={MdMoreVert} />}
                _hover={{ bg: hoverBg }}
                aria-label="Plus d'options"
              />
              <MenuList
                shadow="lg"
                borderColor={borderColor}
                bg={cardBg}
                backdropFilter="blur(10px)"
              >
                <MenuItem 
                  icon={<Icon as={MdEdit} />}
                  onClick={() => {
                    setSelectedChild({
                      ...child,
                      birthDate: formattedDate
                    });
                    onOpen();
                  }}
                  _hover={{ bg: hoverBg }}
                >
                  Modifier
                </MenuItem>
                <MenuItem
                  icon={<Icon as={MdPersonAdd} />}
                  onClick={() => handleTransferSingleChild(child)}
                  color="red.500"
                  _hover={{ bg: hoverBg }}
                >
                  Transférer en adhérent
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </VStack>
      </MotionBox>
    );
  };

  // Render table content based on screen size
  const renderTableContent = (dateStatus) => {
    const children = dateStatus === 'valid' ? childrenData.children.valid :
                    dateStatus === 'invalid' ? childrenData.children.invalid :
                    childrenData.children.missing;
    
    const filteredChildren = filterChildren(children);

    if (filteredChildren.length === 0) {
      return (
        <Box p={4} textAlign="center">
          <Icon 
            as={MdSearch} 
            w={12} 
            h={12} 
            color={mutedTextColor} 
            mb={4} 
          />
          <Text color={mutedTextColor} fontSize="lg" mb={2}>
            Aucun enfant trouvé
          </Text>
          {(searchQuery || ageFilter !== 'all') && (
            <Text fontSize="sm" color={mutedTextColor}>
              Essayez de modifier vos critères de recherche
            </Text>
          )}
        </Box>
      );
    }

    return (
      <Box>
        {/* Mobile View */}
        <Box display={{ base: "block", md: "none" }}>
          <AnimatePresence mode="wait">
            {filteredChildren.map(child => renderMobileRow(child, dateStatus))}
          </AnimatePresence>
        </Box>

        {/* Desktop View */}
        <Box display={{ base: "none", md: "block" }} overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Parent</Th>
                <Th>Date de naissance</Th>
                <Th>Âge</Th>
                <Th w="200px">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <AnimatePresence mode="wait">
                {renderTableRows(dateStatus)}
              </AnimatePresence>
            </Tbody>
          </Table>
        </Box>
      </Box>
    );
  };

  // Fonction pour transférer les enfants de plus de 25 ans
  const transferAdults = async () => {
    setIsTransferring(true);
    try {
      const response = await fetch('https://app.falconmarketing.fr:3004/children/transfer-adults', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors du transfert');
      }

      toast({
        title: "Succès",
        description: `${data.transferredCount} enfant(s) transféré(s) en tant qu'adhérents`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Rafraîchir les données
      fetchChildren();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: "Erreur",
        description: error.message || "Impossible de transférer les enfants",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsTransferring(false);
    }
  };

  // Fonction pour transférer un seul enfant en adhérent
  const transferSingleChild = async (childId) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/children/${childId}/transfer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors du transfert');
      }

      toast({
        title: "Succès",
        description: "Enfant transféré en tant qu'adhérent avec succès",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Rafraîchir les données
      fetchChildren();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: "Erreur",
        description: error.message || "Impossible de transférer l'enfant",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ouvrir la modale de confirmation
  const openTransferConfirmation = (child = null, isMultiple = false) => {
    setChildToTransfer(child);
    setIsMultipleTransfer(isMultiple);
    setIsTransferDialogOpen(true);
  };

  // Fonction pour fermer la modale de confirmation
  const closeTransferConfirmation = () => {
    setChildToTransfer(null);
    setIsMultipleTransfer(false);
    setIsTransferDialogOpen(false);
  };

  // Fonction pour confirmer le transfert
  const confirmTransfer = async () => {
    if (isMultipleTransfer) {
      await transferAdults();
    } else if (childToTransfer) {
      await transferSingleChild(childToTransfer.id);
    }
    closeTransferConfirmation();
  };

  // Fonction pour mettre à jour la date de naissance
  const updateBirthDate = async () => {
    if (!selectedChild?.birthDate) {
      toast({
        title: "Erreur",
        description: "Veuillez entrer une date de naissance",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsUpdating(true);
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/children/${selectedChild.id}/birthdate`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ birthDate: selectedChild.birthDate }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors de la mise à jour');
      }

      toast({
        title: "Succès",
        description: "Date de naissance mise à jour avec succès",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      fetchChildren();
      onClose();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: "Erreur",
        description: error.message || "Impossible de mettre à jour la date de naissance",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  // Modification de la fonction transferAdults pour utiliser la confirmation
  const handleTransferAdults = (count) => {
    setTransferCount(count);
    openTransferConfirmation(null, true);
  };

  // Modification de la fonction transferSingleChild pour utiliser la confirmation
  const handleTransferSingleChild = (child) => {
    openTransferConfirmation(child, false);
  };

  // Responsive styles
  const mobileStyles = {
    container: {
      px: { base: 2, md: 8 },
      maxW: { base: "100%", md: containerWidth }
    },
    header: {
      direction: { base: "column", md: "row" },
      spacing: { base: 4, md: 8 },
      mb: { base: 4, md: 8 }
    },
    stats: {
      direction: { base: "column", md: "row" },
      spacing: { base: 2, md: 4 },
      p: { base: 4, md: 6 }
    },
    statCard: {
      w: { base: "full", md: "auto" },
      p: { base: 3, md: 4 },
      borderRadius: "xl"
    },
    filters: {
      direction: { base: "column", md: "row" },
      spacing: { base: 3, md: 4 },
      p: { base: 3, md: 4 }
    },
    table: {
      display: { base: "block", md: "table" },
      minW: { base: "100%", md: "auto" }
    },
    tableCell: {
      px: { base: 2, md: 4 },
      py: { base: 3, md: 4 }
    },
    pagination: {
      direction: { base: "column", md: "row" },
      spacing: { base: 3, md: 4 },
      p: { base: 3, md: 4 }
    }
  };

  // Render table rows function
  const renderTableRows = (dateStatus) => {
    const children = dateStatus === 'valid' ? childrenData.children.valid :
                    dateStatus === 'invalid' ? childrenData.children.invalid :
                    childrenData.children.missing;
    
    const filteredChildren = filterChildren(children);

    if (filteredChildren.length === 0) {
      return (
        <Tr>
          <Td colSpan={5}>
            <Flex 
              direction="column" 
              align="center" 
              justify="center" 
              py={8}
              opacity={0.7}
            >
              <Icon 
                as={MdSearch} 
                w={12} 
                h={12} 
                color={mutedTextColor} 
                mb={4} 
              />
              <Text color={mutedTextColor} fontSize="lg" mb={2}>
                Aucun enfant trouvé
              </Text>
              {(searchQuery || ageFilter !== 'all') && (
                <Text fontSize="sm" color={mutedTextColor}>
                  Essayez de modifier vos critères de recherche
                </Text>
              )}
            </Flex>
          </Td>
        </Tr>
      );
    }

    return filteredChildren.map((child) => {
      const { age, yearsTo25 } = calculateAge(child.birthDate);
      const ageColor = 
        child.ageCategory === 'over25' ? 'red.500' :
        child.ageCategory === 'critical' ? 'orange.500' :
        child.ageCategory === 'warning' ? 'yellow.500' : 'green.500';

      const formattedDate = dateStatus === 'invalid' ? formatDate(child.birthDate) : child.birthDate;

      return (
        <MotionTr
          key={child.id}
          variants={rowVariants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
        >
          <Td>
            <HStack spacing={3}>
              <Box
                w={2}
                h={2}
                borderRadius="full"
                bg={ageColor}
              />
              <Text fontWeight="medium">{child.name || 'N/A'}</Text>
            </HStack>
          </Td>
          <Td>
            <Text color={mutedTextColor}>{child.parentFullName || 'N/A'}</Text>
          </Td>
          <Td>
            <HStack spacing={2}>
              <Text>{formattedDate || "Non spécifiée"}</Text>
              <Badge
                colorScheme={
                  child.dateStatus === 'valid' ? 'green' :
                  child.dateStatus === 'invalid' ? 'orange' : 'red'
                }
                variant="subtle"
                fontSize="xs"
                px={2}
                borderRadius="full"
              >
                {child.dateStatus === 'valid' ? 'Valide' :
                 child.dateStatus === 'invalid' ? 'À corriger' : 'Manquante'}
              </Badge>
            </HStack>
          </Td>
          <Td>
            <VStack align="start" spacing={1}>
              <Text fontWeight="medium" color={ageColor}>
                {age !== null ? `${age} ans` : "N/A"}
              </Text>
              {yearsTo25 !== null && (
                <Text fontSize="sm" color={mutedTextColor}>
                  {yearsTo25 <= 0 
                    ? 'Plus de 25 ans'
                    : `${yearsTo25} an${yearsTo25 > 1 ? 's' : ''} avant 25 ans`}
                </Text>
              )}
            </VStack>
          </Td>
          <Td>
            <HStack spacing={2}>
              <IconButton
                size="sm"
                variant="ghost"
                colorScheme="gray"
                icon={<Icon as={MdEdit} />}
                onClick={() => {
                  setSelectedChild({
                    ...child,
                    birthDate: formattedDate
                  });
                  onOpen();
                }}
                _hover={{ bg: hoverBg }}
                aria-label="Modifier"
              />
              <IconButton
                size="sm"
                variant="ghost"
                colorScheme="red"
                icon={<Icon as={MdPersonAdd} />}
                onClick={() => handleTransferSingleChild(child)}
                _hover={{ bg: hoverBg }}
                aria-label="Transférer en adhérent"
              />
              <Menu>
                <MenuButton
                  as={IconButton}
                  size="sm"
                  variant="ghost"
                  colorScheme="gray"
                  icon={<Icon as={MdMoreVert} />}
                  _hover={{ bg: hoverBg }}
                  aria-label="Plus d'options"
                />
                <MenuList
                  shadow="lg"
                  borderColor={borderColor}
                  bg={cardBg}
                  backdropFilter="blur(10px)"
                >
                  <MenuItem 
                    icon={<Icon as={MdEdit} />}
                    onClick={() => {
                      setSelectedChild({
                        ...child,
                        birthDate: formattedDate
                      });
                      onOpen();
                    }}
                    _hover={{ bg: hoverBg }}
                  >
                    Modifier
                  </MenuItem>
                  <MenuItem
                    icon={<Icon as={MdPersonAdd} />}
                    onClick={() => handleTransferSingleChild(child)}
                    color="red.500"
                    _hover={{ bg: hoverBg }}
                  >
                    Transférer en adhérent
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Td>
        </MotionTr>
      );
    });
  };

  return (
    <Box
      pt={{ base: "100px", md: "130px", xl: "80px" }}
      minH="100vh"
      bgGradient={bgGradient}
    >
      <Container {...mobileStyles.container}>
        <MotionBox
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {/* Header Section */}
          <Flex {...mobileStyles.header}>
            <VStack align="stretch" spacing={1} mb={{ base: 4, md: 0 }}>
              <Heading
                size={{ base: "md", md: "lg" }}
                fontWeight="bold"
                color={textColor}
                letterSpacing="tight"
              >
                Gestion des Enfants
              </Heading>
              <Text 
                color={mutedTextColor} 
                fontSize={{ base: "sm", md: "md" }}
                display={{ base: "none", md: "block" }}
              >
                Gérez et suivez les informations des enfants
              </Text>
            </VStack>
            <HStack 
              spacing={4} 
              w={{ base: "full", md: "auto" }}
              justify={{ base: "space-between", md: "flex-end" }}
            >
              <Button
                leftIcon={<Icon as={MdRefresh} />}
                variant="ghost"
                onClick={() => fetchChildren()}
                _hover={{ bg: hoverBg }}
                size={{ base: "sm", md: "md" }}
                flex={{ base: 1, md: "auto" }}
              >
                Actualiser
              </Button>
              {activeTab === 0 && ageFilter === "over25" && (
                <Button
                  leftIcon={<Icon as={MdPersonAdd} />}
                  colorScheme="brand"
                  variant="solid"
                  onClick={() => handleTransferAdults(childrenData.stats.adultsCount)}
                  size={{ base: "sm", md: "md" }}
                  flex={{ base: 1, md: "auto" }}
                >
                  Transférer
                </Button>
              )}
            </HStack>
          </Flex>

          {/* Stats Section */}
          <ScaleFade in={!loading} initialScale={0.9}>
            <Box
              bg={cardBg}
              borderRadius="2xl"
              {...mobileStyles.stats}
              boxShadow="xl"
              backdropFilter="blur(10px)"
              border="1px solid"
              borderColor={borderColor}
              mb={{ base: 4, md: 8 }}
            >
              <SimpleGrid 
                columns={{ base: 2, md: 4 }} 
                spacing={{ base: 3, md: 6 }}
                w="full"
              >
                <Stat {...mobileStyles.statCard}>
                  <StatLabel 
                    color={mutedTextColor}
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    Total
                  </StatLabel>
                  <StatNumber 
                    fontSize={{ base: "2xl", md: "3xl" }} 
                    fontWeight="bold" 
                    color={textColor}
                  >
                    {childrenData.stats.total}
                  </StatNumber>
                </Stat>
                <Stat {...mobileStyles.statCard}>
                  <StatLabel 
                    color={mutedTextColor}
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    Valides
                  </StatLabel>
                  <StatNumber
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    color="green.500"
                  >
                    {childrenData.stats.valid}
                  </StatNumber>
                </Stat>
                <Stat {...mobileStyles.statCard}>
                  <StatLabel 
                    color={mutedTextColor}
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    À corriger
                  </StatLabel>
                  <StatNumber
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    color="orange.500"
                  >
                    {childrenData.stats.invalid}
                  </StatNumber>
                </Stat>
                <Stat {...mobileStyles.statCard}>
                  <StatLabel 
                    color={mutedTextColor}
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    Manquants
                  </StatLabel>
                  <StatNumber 
                    fontSize={{ base: "2xl", md: "3xl" }} 
                    fontWeight="bold" 
                    color="red.500"
                  >
                    {childrenData.stats.missing}
                  </StatNumber>
                </Stat>
              </SimpleGrid>
            </Box>
          </ScaleFade>

          {/* Filters Section */}
          <SlideFade in={!loading} offsetY={20}>
            <Flex {...mobileStyles.filters}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={MdSearch} color={iconColor} />
                </InputLeftElement>
                <Input
                  variant="filled"
                  placeholder="Rechercher..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  bg="transparent"
                  _hover={{ bg: hoverBg }}
                  _focus={{ bg: activeBg, borderColor: "brand.500" }}
                  size={{ base: "sm", md: "md" }}
                />
              </InputGroup>
              <Select
                value={ageFilter}
                onChange={(e) => handleAgeFilterChange(e.target.value)}
                variant="filled"
                bg="transparent"
                _hover={{ bg: hoverBg }}
                _focus={{ bg: activeBg, borderColor: "brand.500" }}
                size={{ base: "sm", md: "md" }}
                w={{ base: "full", md: "200px" }}
              >
                <option value="all">Tous les âges</option>
                <option value="over25">25 ans et plus</option>
                <option value="critical">Bientôt 25 ans</option>
                <option value="warning">22-24 ans</option>
                <option value="normal">Moins de 22 ans</option>
              </Select>
            </Flex>
          </SlideFade>

          {/* Content Section */}
          <Fade in={!loading}>
            <Box
              bg={cardBg}
              borderRadius="2xl"
              overflow="hidden"
              boxShadow="xl"
              backdropFilter="blur(10px)"
              border="1px solid"
              borderColor={borderColor}
            >
              {loading ? (
                <Flex justify="center" align="center" h="200px">
                  <Spinner size="xl" color="brand.500" thickness="3px" />
                </Flex>
              ) : (
                <Tabs 
                  onChange={handleTabChange} 
                  index={activeTab} 
                  variant="enclosed" 
                  colorScheme="brand"
                >
                  <TabList px={4} pt={4} borderBottom="1px solid" borderColor={borderColor}>
                    <Tab
                      _selected={{
                        color: "brand.500",
                        bg: glassBg,
                        borderColor: borderColor,
                        borderBottom: "none",
                        mb: "-1px"
                      }}
                      borderRadius="lg lg 0 0"
                      px={6}
                      py={3}
                    >
                      <HStack spacing={2}>
                        <Text>Dates valides</Text>
                        <Badge colorScheme="green" variant="subtle" borderRadius="full">
                          {childrenData.stats.valid}
                        </Badge>
                      </HStack>
                    </Tab>
                    <Tab
                      _selected={{
                        color: "orange.500",
                        bg: glassBg,
                        borderColor: borderColor,
                        borderBottom: "none",
                        mb: "-1px"
                      }}
                      borderRadius="lg lg 0 0"
                      px={6}
                      py={3}
                    >
                      <HStack spacing={2}>
                        <Text>À corriger</Text>
                        <Badge colorScheme="orange" variant="subtle" borderRadius="full">
                          {childrenData.stats.invalid}
                        </Badge>
                      </HStack>
                    </Tab>
                    <Tab
                      _selected={{
                        color: "red.500",
                        bg: glassBg,
                        borderColor: borderColor,
                        borderBottom: "none",
                        mb: "-1px"
                      }}
                      borderRadius="lg lg 0 0"
                      px={6}
                      py={3}
                    >
                      <HStack spacing={2}>
                        <Text>Manquantes</Text>
                        <Badge colorScheme="red" variant="subtle" borderRadius="full">
                          {childrenData.stats.missing}
                        </Badge>
                      </HStack>
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel p={0}>
                      {renderTableContent('valid')}
                    </TabPanel>
                    <TabPanel p={0}>
                      {renderTableContent('invalid')}
                    </TabPanel>
                    <TabPanel p={0}>
                      {renderTableContent('missing')}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </Box>
          </Fade>

          {/* Pagination Section */}
          <Flex
            justify="center"
            align="center"
            mt={6}
            mb={8}
            bg={glassBg}
            p={4}
            borderRadius="xl"
            backdropFilter="blur(10px)"
          >
            <Button
              size="sm"
              variant="ghost"
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
              _hover={{ bg: hoverBg }}
            >
              Précédent
            </Button>
            <Text mx={4} color={textColor}>
              Page {currentPage} sur {childrenData.pagination.totalPages}
            </Text>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === childrenData.pagination.totalPages}
              _hover={{ bg: hoverBg }}
            >
              Suivant
            </Button>
            <Select
              size="sm"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              ml={4}
              w="100px"
              variant="filled"
              bg="transparent"
              _hover={{ bg: hoverBg }}
            >
              <option value={5}>5 / page</option>
              <option value={10}>10 / page</option>
              <option value={20}>20 / page</option>
              <option value={50}>50 / page</option>
            </Select>
          </Flex>
        </MotionBox>
      </Container>

      {/* Edit Modal */}
      <Modal 
        isOpen={isOpen} 
        onClose={onClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent
          bg={cardBg}
          backdropFilter="blur(10px)"
          borderRadius="2xl"
          border="1px solid"
          borderColor={borderColor}
          shadow="2xl"
        >
          <ModalHeader 
            borderBottom="1px solid" 
            borderColor={borderColor}
            py={4}
          >
            <Text fontSize="lg" fontWeight="semibold">
              Modifier la date de naissance
            </Text>
          </ModalHeader>
          <ModalBody py={6}>
            <FormControl>
              <FormLabel color={mutedTextColor}>
                Date de naissance (JJ/MM/AAAA)
              </FormLabel>
              <Input
                variant="filled"
                placeholder="JJ/MM/AAAA"
                value={selectedChild?.birthDate || ""}
                onChange={(e) => {
                  setSelectedChild({
                    ...selectedChild,
                    birthDate: e.target.value
                  });
                }}
                pattern="\d{2}/\d{2}/\d{4}"
                bg="transparent"
                _hover={{ bg: hoverBg }}
                _focus={{ bg: activeBg, borderColor: "brand.500" }}
                isInvalid={selectedChild?.birthDate && !/^\d{2}\/\d{2}\/\d{4}$/.test(selectedChild.birthDate)}
              />
              <Text fontSize="sm" color={mutedTextColor} mt={2}>
                Format requis: JJ/MM/AAAA (exemple: 25/12/2000)
              </Text>
              {selectedChild?.ageCategory === 'over25' && (
                <HStack 
                  spacing={2} 
                  bg="red.50" 
                  color="red.600" 
                  p={3} 
                  borderRadius="md"
                  mt={4}
                >
                  <Icon as={MdWarning} />
                  <Text fontSize="sm">
                    Cet enfant a plus de 25 ans. Pensez à le transférer vers les adhérents.
                  </Text>
                </HStack>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter 
            borderTop="1px solid" 
            borderColor={borderColor}
            py={4}
          >
            <Button
              variant="ghost"
              mr={3}
              onClick={onClose}
              isDisabled={isUpdating}
              _hover={{ bg: hoverBg }}
            >
              Annuler
            </Button>
            <Button
              colorScheme="brand"
              onClick={updateBirthDate}
              isLoading={isUpdating}
              loadingText="Mise à jour..."
              isDisabled={!selectedChild?.birthDate || !/^\d{2}\/\d{2}\/\d{4}$/.test(selectedChild.birthDate)}
            >
              Enregistrer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Transfer Confirmation Dialog */}
      <AlertDialog
        isOpen={isTransferDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeTransferConfirmation}
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay backdropFilter="blur(4px)">
          <AlertDialogContent
            bg={cardBg}
            backdropFilter="blur(10px)"
            borderRadius="2xl"
            border="1px solid"
            borderColor={borderColor}
            shadow="2xl"
          >
            <AlertDialogHeader 
              fontSize="lg" 
              fontWeight="semibold"
              borderBottom="1px solid"
              borderColor={borderColor}
              py={4}
            >
              Confirmer le transfert
            </AlertDialogHeader>

            <AlertDialogBody py={6}>
              {isMultipleTransfer ? (
                <VStack align="start" spacing={4}>
                  <Text>
                    Êtes-vous sûr de vouloir transférer ces {transferCount} enfants en adhérents ?
                  </Text>
                  <Text color="red.500" fontSize="sm">
                    Cette action est irréversible.
                  </Text>
                </VStack>
              ) : (
                <VStack align="start" spacing={4}>
                  <Text>
                    Êtes-vous sûr de vouloir transférer {childToTransfer?.name || 'cet enfant'} en adhérent ?
                  </Text>
                  <Text color="red.500" fontSize="sm">
                    Cette action est irréversible.
                  </Text>
                </VStack>
              )}
            </AlertDialogBody>

            <AlertDialogFooter
              borderTop="1px solid"
              borderColor={borderColor}
              py={4}
            >
              <Button
                ref={cancelRef}
                variant="ghost"
                onClick={closeTransferConfirmation}
                _hover={{ bg: hoverBg }}
              >
                Annuler
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmTransfer}
                ml={3}
                isLoading={isTransferring}
                loadingText="Transfert en cours..."
                leftIcon={<Icon as={MdPersonAdd} />}
              >
                Confirmer le transfert
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ChildrenManagement;