import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useToast,
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  Spinner,
  HStack,
  useColorModeValue,
  Container,
  VStack,
  Heading,
  InputGroup,
  InputLeftElement,
  Badge,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Icon,
  Divider,
  useBreakpointValue,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Show,
  Hide,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FiEdit2, FiSave, FiX, FiTrash2, FiUserPlus, FiSearch, FiUsers, FiUserCheck } from 'react-icons/fi';

const MotionBox = motion(Box);
const MotionButton = motion(Button);
const MotionFlex = motion(Flex);

const DelegateManagement = () => {
  const [delegates, setDelegates] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDelegates, setFilteredDelegates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newDelegateName, setNewDelegateName] = useState('');
  const toast = useToast();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const [selectedDelegate, setSelectedDelegate] = useState(null);

  // Enhanced color mode values
  const bgGradient = useColorModeValue(
    'linear(to-b, blue.50, white)',
    'linear(to-b, gray.900, gray.800)'
  );
  const boxBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const tableBgColor = useColorModeValue('white', 'gray.700');
  const tableHeaderBgColor = useColorModeValue('gray.50', 'gray.700');
  const tableRowHoverBg = useColorModeValue('gray.50', 'gray.600');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const modalBgColor = useColorModeValue('white', 'gray.800');
  const iconColor = useColorModeValue('blue.500', 'blue.300');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');
  const statBg = useColorModeValue('blue.50', 'gray.700');

  // Animation variants
  const containerAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  // Stats calculations
  const stats = {
    totalDelegates: delegates.length,
    totalAdherents: delegates.reduce((acc, curr) => acc + curr.adherentCount, 0),
    avgAdherentsPerDelegate: Math.round(
      delegates.reduce((acc, curr) => acc + curr.adherentCount, 0) / (delegates.length || 1)
    ),
    activeDelegates: delegates.filter(d => d.adherentCount > 0).length
  };

  const fetchDelegates = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        'https://app.falconmarketing.fr:3004/delegates/details'
      );
      const data = await response.json();
      if (data.delegates && Array.isArray(data.delegates)) {
        setDelegates(
          data.delegates.map((delegate) => ({
            id: delegate.delegateName,
            delegateName: delegate.delegateName,
            adherentCount: delegate.adherentCount || 0,
          }))
        );
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des délégués:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger les délégués',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDelegates();
  }, []);

  useEffect(() => {
    const filtered = delegates.filter((delegate) =>
      delegate.delegateName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDelegates(filtered);
  }, [searchTerm, delegates]);

  const handleEdit = (delegate) => {
    setEditingId(delegate.delegateName);
    setEditValue(delegate.delegateName);
  };

  const handleSave = async (delegate) => {
    try {
      const response = await fetch(
        `https://app.falconmarketing.fr:3004/delegates/${delegate.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            oldName: delegate.delegateName,
            newName: editValue,
          }),
        }
      );

      if (!response.ok) throw new Error('Erreur lors de la mise à jour');

      const data = await response.json();

      setDelegates((prevDelegates) =>
        prevDelegates.map((d) =>
          d.delegateName === delegate.delegateName
            ? {
                ...d,
                delegateName: editValue,
                adherentCount: data.updatedAdherents,
              }
            : d
        )
      );

      toast({
        title: 'Succès',
        description: `Délégué mis à jour. ${data.updatedAdherents} adhérents affectés.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setEditingId(null);
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le délégué',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://app.falconmarketing.fr:3004/delegates/${selectedDelegate.delegateName}`,
        {
          method: 'DELETE',
        }
      );

      if (!response.ok) throw new Error('Erreur lors de la suppression');

      setDelegates((prev) =>
        prev.filter((d) => d.delegateName !== selectedDelegate.delegateName)
      );

      toast({
        title: 'Succès',
        description: 'Délégué supprimé avec succès',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onDeleteClose();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le délégué',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddDelegate = async () => {
    try {
      const response = await fetch(
        'https://app.falconmarketing.fr:3004/delegates',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ delegateName: newDelegateName }),
        }
      );

      if (!response.ok) throw new Error("Erreur lors de l'ajout");

      const data = await response.json();

      setDelegates((prev) => [
        ...prev,
        {
          id: newDelegateName,
          delegateName: newDelegateName,
          adherentCount: 0,
        },
      ]);

      toast({
        title: 'Succès',
        description: 'Délégué ajouté avec succès',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNewDelegateName('');
      onAddClose();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter le délégué",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add breakpoint values for responsive design
  const isMobile = useBreakpointValue({ base: true, md: false });
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });
  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const stackSpacing = useBreakpointValue({ base: 4, md: 8 });
  const containerPadding = useBreakpointValue({ base: 2, md: 8 });

  return (
    <Box 
      minH="100vh"
      bgGradient={bgGradient}
      py={{ base: 2, md: 8 }}
      px={{ base: 2, md: 0 }}
      transition="all 0.2s"
    >
      <Container maxW="7xl" px={containerPadding}>
        <MotionBox
          initial="hidden"
          animate="visible"
          variants={containerAnimation}
        >
          <VStack spacing={stackSpacing}>
            {/* Header Section - Adjusted for mobile */}
            <MotionBox 
              w="full" 
              textAlign="center" 
              py={{ base: 4, md: 8 }}
              variants={itemAnimation}
            >
              <Heading 
                size={{ base: "lg", md: "xl" }}
                color={textColor}
                fontWeight="bold"
                letterSpacing="tight"
                mb={2}
              >
                Gestion des Délégués
              </Heading>
              <Text
                fontSize={{ base: "md", md: "lg" }}
                color={secondaryTextColor}
              >
                Administration et suivi des délégués
              </Text>
            </MotionBox>

            {/* Stats Section - Responsive grid */}
            <SimpleGrid 
              columns={{ base: 2, md: 4 }} 
              spacing={{ base: 3, md: 6 }} 
              w="full"
            >
              <Stat
                px={{ base: 3, md: 6 }}
                py={{ base: 2, md: 4 }}
                bg={statBg}
                borderRadius="lg"
                boxShadow="sm"
              >
                <StatLabel fontSize={{ base: "xs", md: "sm" }} color={secondaryTextColor}>Total Délégués</StatLabel>
                <StatNumber fontSize={{ base: "lg", md: "xl" }} color={textColor}>{stats.totalDelegates}</StatNumber>
              </Stat>
              <Stat
                px={{ base: 3, md: 6 }}
                py={{ base: 2, md: 4 }}
                bg={statBg}
                borderRadius="lg"
                boxShadow="sm"
              >
                <StatLabel fontSize={{ base: "xs", md: "sm" }} color={secondaryTextColor}>Total Adhérents</StatLabel>
                <StatNumber fontSize={{ base: "lg", md: "xl" }} color={textColor}>{stats.totalAdherents}</StatNumber>
              </Stat>
              <Stat
                px={{ base: 3, md: 6 }}
                py={{ base: 2, md: 4 }}
                bg={statBg}
                borderRadius="lg"
                boxShadow="sm"
              >
                <StatLabel fontSize={{ base: "xs", md: "sm" }} color={secondaryTextColor}>Moyenne Adhérents/Délégué</StatLabel>
                <StatNumber fontSize={{ base: "lg", md: "xl" }} color={textColor}>{stats.avgAdherentsPerDelegate}</StatNumber>
              </Stat>
              <Stat
                px={{ base: 3, md: 6 }}
                py={{ base: 2, md: 4 }}
                bg={statBg}
                borderRadius="lg"
                boxShadow="sm"
              >
                <StatLabel fontSize={{ base: "xs", md: "sm" }} color={secondaryTextColor}>Délégués Actifs</StatLabel>
                <StatNumber fontSize={{ base: "lg", md: "xl" }} color={textColor}>{stats.activeDelegates}</StatNumber>
              </Stat>
            </SimpleGrid>

            {/* Controls Section - Mobile optimized */}
            <MotionBox
              variants={itemAnimation}
              w="full"
              bg={boxBg}
              p={{ base: 3, md: 6 }}
              borderRadius="xl"
              boxShadow="lg"
              border="1px"
              borderColor={borderColor}
            >
              <Stack 
                direction={{ base: "column", md: "row" }} 
                justify="space-between" 
                align={{ base: "stretch", md: "center" }}
                spacing={{ base: 3, md: 4 }}
                w="full"
              >
                <InputGroup maxW={{ base: "full", md: "300px" }}>
                  <InputLeftElement pointerEvents="none">
                    <FiSearch color={iconColor} />
                  </InputLeftElement>
                  <Input
                    placeholder="Rechercher un délégué..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    bg={inputBgColor}
                    size={buttonSize}
                    _hover={{ bg: tableRowHoverBg }}
                  />
                </InputGroup>
                <MotionButton
                  leftIcon={<FiUserPlus />}
                  colorScheme="blue"
                  onClick={onAddOpen}
                  size={buttonSize}
                  w={{ base: "full", md: "auto" }}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Ajouter un Délégué
                </MotionButton>
              </Stack>
            </MotionBox>

            {/* Table Section - Mobile optimized */}
            <MotionBox
              variants={itemAnimation}
              w="full"
              bg={boxBg}
              borderRadius="xl"
              boxShadow="lg"
              border="1px"
              borderColor={borderColor}
              overflow="hidden"
            >
              {isLoading ? (
                <Flex justify="center" align="center" minH="200px">
                  <Spinner size="xl" color={iconColor} thickness="4px" />
                </Flex>
              ) : (
                <>
                  {/* Desktop Table View */}
                  <Hide below="md">
                    <Table variant="simple" size={tableSize}>
                      <Thead bg={tableHeaderBgColor}>
                        <Tr>
                          <Th color={secondaryTextColor}>Nom du Délégué</Th>
                          <Th color={secondaryTextColor} isNumeric>Adhérents</Th>
                          <Th color={secondaryTextColor}>Statut</Th>
                          <Th color={secondaryTextColor}>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {filteredDelegates.map((delegate) => (
                          <Tr 
                            key={delegate.delegateName}
                            _hover={{ bg: tableRowHoverBg }}
                            transition="background 0.2s"
                          >
                            <Td>
                              {editingId === delegate.delegateName ? (
                                <Input
                                  value={editValue}
                                  onChange={(e) => setEditValue(e.target.value)}
                                  size="sm"
                                  bg={inputBgColor}
                                  borderColor={borderColor}
                                />
                              ) : (
                                <HStack>
                                  <Icon as={FiUserCheck} color={iconColor} />
                                  <Text color={textColor}>{delegate.delegateName}</Text>
                                </HStack>
                              )}
                            </Td>
                            <Td isNumeric>
                              <Badge
                                colorScheme={delegate.adherentCount > 0 ? 'green' : 'gray'}
                                variant="subtle"
                                fontSize="sm"
                                px={3}
                                py={1}
                                borderRadius="full"
                              >
                                {delegate.adherentCount}
                              </Badge>
                            </Td>
                            <Td>
                              <Badge
                                colorScheme={delegate.adherentCount > 0 ? 'blue' : 'yellow'}
                                variant="subtle"
                                fontSize="sm"
                              >
                                {delegate.adherentCount > 0 ? 'Actif' : 'Inactif'}
                              </Badge>
                            </Td>
                            <Td>
                              <HStack spacing={2}>
                                {editingId === delegate.delegateName ? (
                                  <>
                                    <Tooltip label="Sauvegarder" hasArrow>
                                      <MotionButton
                                        as={IconButton}
                                        icon={<FiSave />}
                                        colorScheme="green"
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => handleSave(delegate)}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                      />
                                    </Tooltip>
                                    <Tooltip label="Annuler" hasArrow>
                                      <MotionButton
                                        as={IconButton}
                                        icon={<FiX />}
                                        colorScheme="red"
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => setEditingId(null)}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                      />
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip label="Modifier" hasArrow>
                                      <MotionButton
                                        as={IconButton}
                                        icon={<FiEdit2 />}
                                        colorScheme="blue"
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => handleEdit(delegate)}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                      />
                                    </Tooltip>
                                    <Tooltip label="Supprimer" hasArrow>
                                      <MotionButton
                                        as={IconButton}
                                        icon={<FiTrash2 />}
                                        colorScheme="red"
                                        size="sm"
                                        variant="ghost"
                                        onClick={() => {
                                          setSelectedDelegate(delegate);
                                          onDeleteOpen();
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Hide>

                  {/* Mobile List View */}
                  <Show below="md">
                    <VStack spacing={2} p={2} divider={<Divider />}>
                      {filteredDelegates.map((delegate) => (
                        <Box
                          key={delegate.delegateName}
                          w="full"
                          p={3}
                          bg={boxBg}
                          borderRadius="lg"
                          borderWidth="1px"
                          borderColor={borderColor}
                        >
                          <VStack align="stretch" spacing={2}>
                            {/* Delegate Name */}
                            <HStack justify="space-between">
                              {editingId === delegate.delegateName ? (
                                <Input
                                  value={editValue}
                                  onChange={(e) => setEditValue(e.target.value)}
                                  size="sm"
                                  bg={inputBgColor}
                                  borderColor={borderColor}
                                />
                              ) : (
                                <HStack flex={1}>
                                  <Icon as={FiUserCheck} color={iconColor} />
                                  <Text color={textColor} fontWeight="medium">
                                    {delegate.delegateName}
                                  </Text>
                                </HStack>
                              )}
                            </HStack>

                            {/* Stats and Status */}
                            <HStack justify="space-between" wrap="wrap" spacing={4}>
                              <Badge
                                colorScheme={delegate.adherentCount > 0 ? 'green' : 'gray'}
                                variant="subtle"
                                fontSize="sm"
                                px={3}
                                py={1}
                                borderRadius="full"
                              >
                                {delegate.adherentCount} adhérents
                              </Badge>
                              <Badge
                                colorScheme={delegate.adherentCount > 0 ? 'blue' : 'yellow'}
                                variant="subtle"
                                fontSize="sm"
                              >
                                {delegate.adherentCount > 0 ? 'Actif' : 'Inactif'}
                              </Badge>
                            </HStack>

                            {/* Actions */}
                            <HStack justify="flex-end" spacing={2}>
                              {editingId === delegate.delegateName ? (
                                <>
                                  <IconButton
                                    icon={<FiSave />}
                                    colorScheme="green"
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => handleSave(delegate)}
                                    aria-label="Sauvegarder"
                                  />
                                  <IconButton
                                    icon={<FiX />}
                                    colorScheme="red"
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setEditingId(null)}
                                    aria-label="Annuler"
                                  />
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    icon={<FiEdit2 />}
                                    colorScheme="blue"
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => handleEdit(delegate)}
                                    aria-label="Modifier"
                                  />
                                  <IconButton
                                    icon={<FiTrash2 />}
                                    colorScheme="red"
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => {
                                      setSelectedDelegate(delegate);
                                      onDeleteOpen();
                                    }}
                                    aria-label="Supprimer"
                                  />
                                </>
                              )}
                            </HStack>
                          </VStack>
                        </Box>
                      ))}
                    </VStack>
                  </Show>
                </>
              )}
            </MotionBox>
          </VStack>
        </MotionBox>
      </Container>

      {/* Modals - Mobile optimized */}
      <Modal 
        isOpen={isDeleteOpen} 
        onClose={onDeleteClose}
        motionPreset="slideInBottom"
        size={isMobile ? "full" : "md"}
      >
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent 
          bg={modalBgColor} 
          borderRadius={isMobile ? "0" : "xl"} 
          m={isMobile ? 0 : 4}
          position={isMobile ? "fixed" : "relative"}
          bottom={isMobile ? 0 : "auto"}
        >
          <ModalHeader 
            borderBottomWidth="1px" 
            borderColor={borderColor}
            py={6}
          >
            <HStack>
              <Icon as={FiTrash2} color="red.500" boxSize={5} />
              <Text color={textColor}>Supprimer le délégué</Text>
            </HStack>
          </ModalHeader>
          <ModalBody py={6}>
            <VStack align="stretch" spacing={4}>
              <Text color={textColor}>
                Êtes-vous sûr de vouloir supprimer ce délégué ? Cette action est irréversible.
              </Text>
              {selectedDelegate && (
                <Box bg={tableRowHoverBg} p={4} borderRadius="md">
                  <HStack justify="space-between">
                    <Text color={secondaryTextColor}>Délégué :</Text>
                    <Text color={textColor} fontWeight="bold">{selectedDelegate.delegateName}</Text>
                  </HStack>
                  <HStack justify="space-between" mt={2}>
                    <Text color={secondaryTextColor}>Adhérents gérés :</Text>
                    <Badge colorScheme="blue">{selectedDelegate.adherentCount}</Badge>
                  </HStack>
                </Box>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter 
            borderTopWidth="1px" 
            borderColor={borderColor}
            py={6}
          >
            <HStack spacing={3}>
              <MotionButton
                variant="ghost"
                onClick={onDeleteClose}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Annuler
              </MotionButton>
              <MotionButton
                colorScheme="red"
                onClick={handleDelete}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Supprimer
              </MotionButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal 
        isOpen={isAddOpen} 
        onClose={onAddClose}
        motionPreset="slideInBottom"
        size={isMobile ? "full" : "md"}
      >
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent 
          bg={modalBgColor} 
          borderRadius={isMobile ? "0" : "xl"} 
          m={isMobile ? 0 : 4}
          position={isMobile ? "fixed" : "relative"}
          bottom={isMobile ? 0 : "auto"}
        >
          <ModalHeader 
            borderBottomWidth="1px" 
            borderColor={borderColor}
            py={6}
          >
            <HStack>
              <Icon as={FiUserPlus} color={iconColor} boxSize={5} />
              <Text color={textColor}>Ajouter un Délégué</Text>
            </HStack>
          </ModalHeader>
          <ModalBody py={6}>
            <VStack spacing={4}>
              <Text color={secondaryTextColor} fontSize="sm">
                Entrez le nom du nouveau délégué à ajouter à l'association.
              </Text>
              <Input
                placeholder="Nom du délégué"
                value={newDelegateName}
                onChange={(e) => setNewDelegateName(e.target.value)}
                size="lg"
                bg={inputBgColor}
                borderColor={borderColor}
                _hover={{ bg: tableRowHoverBg }}
              />
            </VStack>
          </ModalBody>
          <ModalFooter 
            borderTopWidth="1px" 
            borderColor={borderColor}
            py={6}
          >
            <HStack spacing={3}>
              <MotionButton
                variant="ghost"
                onClick={onAddClose}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Annuler
              </MotionButton>
              <MotionButton
                colorScheme="blue"
                onClick={handleAddDelegate}
                isDisabled={!newDelegateName.trim()}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Ajouter
              </MotionButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DelegateManagement;
