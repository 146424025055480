import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  useToast,
  SimpleGrid,
  useColorModeValue,
  Icon,
  Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FiFileText, FiDownload, FiUsers, FiActivity } from 'react-icons/fi';
import { Document, Page, Text as PDFText, View, StyleSheet, Font, pdf } from '@react-pdf/renderer';
import AdherentPDF from '../default/components/AdherentPDF';

// Enregistrement de la police pour le PDF
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

// Styles pour le PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
    fontFamily: 'Roboto',
  },
  header: {
    marginBottom: 20,
    borderBottomWidth: 2,
    borderBottomColor: '#1a365d',
    paddingBottom: 8,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    color: '#1a365d',
    fontWeight: 700,
    marginBottom: 8,
  },
  subtitle: {
    fontSize: 10,
    textAlign: 'center',
    color: '#4a5568',
    marginBottom: 15,
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#2c5282',
    marginBottom: 8,
    backgroundColor: '#EDF2F7',
    padding: 6,
    borderRadius: 4,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 6,
    paddingBottom: 3,
    borderBottomWidth: 1,
    borderBottomColor: '#E2E8F0',
  },
  label: {
    width: '40%',
    fontSize: 10,
    color: '#4a5568',
    fontWeight: 500,
  },
  value: {
    width: '60%',
    fontSize: 10,
    color: '#1a202c',
  },
  expensesGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
  expenseItem: {
    width: '50%',
    flexDirection: 'row',
    marginBottom: 6,
    paddingRight: 8,
  },
  totalSection: {
    marginTop: 15,
    borderTopWidth: 2,
    borderTopColor: '#E2E8F0',
    paddingTop: 8,
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 5,
  },
  totalLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: '#2c5282',
    marginRight: 20,
  },
  totalValue: {
    fontSize: 14,
    fontWeight: 700,
    color: '#1a365d',
  },
  observations: {
    marginTop: 15,
    padding: 8,
    backgroundColor: '#F7FAFC',
    borderRadius: 4,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#718096',
    fontSize: 8,
    paddingTop: 8,
    borderTopWidth: 1,
    borderTopColor: '#E2E8F0',
  },
});

// Composant PDF pour la fiche décès
const FicheDecesPDF = ({ formData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* En-tête */}
      <View style={styles.header}>
        <PDFText style={styles.title}>Fiche de décès</PDFText>
        <PDFText style={styles.subtitle}>Association El Mektoub</PDFText>
      </View>

      {/* Informations générales */}
      <View style={styles.section}>
        <PDFText style={styles.sectionTitle}>Informations générales</PDFText>
        <View style={styles.row}>
          <PDFText style={styles.label}>Délégué</PDFText>
          <PDFText style={styles.value}>_______________________</PDFText>
        </View>
        <View style={styles.row}>
          <PDFText style={styles.label}>Nom du défunt</PDFText>
          <PDFText style={styles.value}>_______________________</PDFText>
        </View>
        <View style={styles.row}>
          <PDFText style={styles.label}>Enfant</PDFText>
          <PDFText style={styles.value}>☐ OUI    ☐ NON</PDFText>
        </View>
        <View style={styles.row}>
          <PDFText style={styles.label}>Date de décès</PDFText>
          <PDFText style={styles.value}>____/____/________</PDFText>
        </View>
      </View>

      {/* Lieux */}
      <View style={styles.section}>
        <PDFText style={styles.sectionTitle}>Lieux</PDFText>
        <View style={styles.row}>
          <PDFText style={styles.label}>Lieu de décès</PDFText>
          <PDFText style={styles.value}>_______________________</PDFText>
        </View>
        <View style={styles.row}>
          <PDFText style={styles.label}>Lieu de décès (autre)</PDFText>
          <PDFText style={styles.value}>_______________________</PDFText>
        </View>
        <View style={styles.row}>
          <PDFText style={styles.label}>Lieu d'enterrement</PDFText>
          <PDFText style={styles.value}>_______________________</PDFText>
        </View>
      </View>

      {/* Dépenses */}
      <View style={styles.section}>
        <PDFText style={styles.sectionTitle}>Dépenses</PDFText>
        <View style={styles.expensesGrid}>
          {[
            'Funérarium',
            'Toilette',
            'Pompes funèbres',
            "Billet d'accompagnement",
            'Traitement',
            'Repas',
            'Concession',
            'Décès en Algérie',
          ].map((label) => (
            <View style={styles.expenseItem} key={label}>
              <PDFText style={styles.label}>{label}</PDFText>
              <PDFText style={styles.value}>_____________ €</PDFText>
            </View>
          ))}
        </View>
      </View>

      {/* Total */}
      <View style={styles.totalSection}>
        <View style={styles.totalRow}>
          <PDFText style={styles.totalLabel}>Total</PDFText>
          <PDFText style={styles.totalValue}>_____________ €</PDFText>
        </View>
        <View style={styles.totalRow}>
          <PDFText style={styles.totalLabel}>Montant restant</PDFText>
          <PDFText style={styles.totalValue}>_____________ €</PDFText>
        </View>
      </View>

      {/* Observations - version plus compacte */}
      <View style={styles.observations}>
        <PDFText style={styles.sectionTitle}>Observations</PDFText>
        <PDFText style={styles.value}>________________________________________________</PDFText>
        <PDFText style={styles.value}>________________________________________________</PDFText>
      </View>

      {/* Pied de page */}
      <View style={styles.footer}>
        <PDFText>Association El Mektoub</PDFText>
      </View>
    </Page>
  </Document>
);

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const DocumentCard = ({ title, description, icon, onClick, isLoading }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const iconColor = useColorModeValue('blue.500', 'blue.300');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <MotionBox
      bg={cardBg}
      p={8}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="lg"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-4px)', boxShadow: 'xl' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      cursor="pointer"
      onClick={onClick}
    >
      <Icon 
        as={icon} 
        boxSize={12} 
        color={iconColor} 
        mb={4}
      />
      <Heading 
        size="md" 
        mb={4}
        color={useColorModeValue('gray.800', 'white')}
      >
        {title}
      </Heading>
      <Text 
        color={textColor}
        mb={6}
        fontSize="sm"
      >
        {description}
      </Text>
      <MotionButton
        leftIcon={<FiDownload />}
        colorScheme="blue"
        size="lg"
        width="full"
        isLoading={isLoading}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        Télécharger
      </MotionButton>
    </MotionBox>
  );
};

const DocumentsPage = () => {
  const toast = useToast();
  const bgGradient = useColorModeValue(
    'linear(to-b, blue.50, white)',
    'linear(to-b, gray.900, gray.800)'
  );
  const headingColor = useColorModeValue('gray.800', 'white');
  const subHeadingColor = useColorModeValue('gray.600', 'gray.400');

  const handleDownloadTemplate = async () => {
    try {
      const emptyAdherent = {
        mrName: '',
        mrsName: '',
        birthDate: '',
        address: '',
        phone: '',
        email: '',
        socialSecurityNumber: '',
        nationality: '',
        burialSite: '',
        maritalStatus: '',
        delegateName: '',
        contributionUpdatedAt: new Date(),
      };

      const blob = await pdf(
        <AdherentPDF adherent={emptyAdherent} children={[]} files={[]} />
      ).toBlob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'modele_adherent.pdf';
      link.click();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Erreur lors du téléchargement du modèle',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDownloadDeathTemplate = async () => {
    try {
      const blob = await pdf(<FicheDecesPDF formData={{}} />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'modele_fiche_deces.pdf';
      link.click();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Erreur lors du téléchargement du modèle de fiche décès',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box 
      minH="100vh"
      bgGradient={bgGradient}
      py={{ base: 8, md: 20 }}
      px={4}
    >
      <Container maxW="6xl">
        <VStack spacing={12} align="stretch">
          <MotionBox
            textAlign="center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading
              as="h1"
              size="2xl"
              color={headingColor}
              fontWeight="bold"
              mb={4}
            >
              Documents
            </Heading>
            <Text
              fontSize="xl"
              color={subHeadingColor}
              maxW="2xl"
              mx="auto"
            >
              Téléchargez les modèles de documents officiels de l'association
            </Text>
          </MotionBox>

          <SimpleGrid 
            columns={{ base: 1, md: 2 }} 
            spacing={8}
            pt={8}
          >
            <DocumentCard
              title="Fiche Adhérent"
              description="Formulaire officiel pour l'enregistrement d'un nouvel adhérent à l'association"
              icon={FiUsers}
              onClick={handleDownloadTemplate}
            />
            <DocumentCard
              title="Fiche Décès"
              description="Document de déclaration et de suivi pour les cas de décès"
              icon={FiActivity}
              onClick={handleDownloadDeathTemplate}
            />
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  );
};

export default DocumentsPage; 