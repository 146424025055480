import React, { useState, useEffect } from 'react';
import { Document, Page, View, Text, StyleSheet, Font, pdf } from '@react-pdf/renderer';
import {
  Box,
  Flex,
  Heading,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Grid,
  GridItem,
  Stack,
  VStack,
  HStack,
  SimpleGrid,
  InputGroup,
  InputRightElement,
  Select,
  useColorModeValue,
  Container,
  Divider,
  Text as ChakraText,
  Icon,
  Badge
} from '@chakra-ui/react';
import { format } from 'date-fns';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FiUser, FiCalendar, FiMapPin, FiFileText, FiDollarSign, FiAlertCircle } from 'react-icons/fi';
import { motion } from 'framer-motion';



// Importer la police de caractères
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 20, // Réduit de 30 à 20 pour économiser de l'espace
    backgroundColor: '#E7E9EB'
  },
  heading: {
    fontSize: 18, // Réduit pour occuper moins d'espace
    fontFamily: 'Oswald',
    color: '#333',
    textAlign: 'center',
    marginBottom: 15 // Réduit pour économiser de l'espace
  },
  section: {
    marginBottom: 15, // Réduit pour une meilleure utilisation de l'espace
    padding: 10, // Légère réduction pour économiser de l'espace
    backgroundColor: '#FFF',
    borderRadius: 5
  },
  sectionHeading: {
    fontSize: 14, // Taille réduite pour une meilleure économie de l'espace
    fontFamily: 'Oswald',
    color: '#555',
    marginBottom: 8 // Réduit pour économiser de l'espace
  },
  label: {
    fontSize: 12, // Taille réduite pour une cohérence et économie d'espace
    fontFamily: 'Oswald',
    color: '#777'
  },
  value: {
    fontSize: 12, // Assure une uniformité avec les labels
    fontFamily: 'Oswald',
    color: '#333',
    marginBottom: 4 // Réduit légèrement
  },
  footer: {
    fontSize: 10, // Réduit pour occuper moins d'espace
    fontFamily: 'Oswald',
    color: '#999',
    textAlign: 'center',
    marginTop: 20 // Réduit pour une meilleure économie d'espace
  }
});

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const FicheDeces = () => {
  const [formData, setFormData] = useState({
    delegue: '',
    nomDefunt: '',
    enfant: false,
    dateDecesLieu: format(new Date(), 'yyyy-MM-dd'), // Initialiser avec la date d'aujourd'hui
    lieuDeces: '',
    lieuDeces2: '',
    lieuEnterrement: '',
    funerarium: '',
    toilette: '',
    pompesFunebres: '',
    billetAccompagnement: '',
    traitement: '',
    repas: '',
    concession: '',
    decesAlgerie: '',
    total: 0,
    montantRestant: '',
    observations: ''
  });


  const numericFields = [
    'funerarium',
    'toilette',
    'pompesFunebres',
    'billetAccompagnement',
    'traitement',
    'repas',
    'concession',
    'decesAlgerie',
  ];

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const { mrName, mrsName, delegateName } = location.state || {};
  

  const validateForm = () => {
    let newErrors = {};
    if (!formData.delegue) newErrors.delegue = "Ce champ est obligatoire.";
    if (!formData.nomDefunt.trim()) newErrors.nomDefunt = "Ce champ est obligatoire.";
    if (!formData.dateDecesLieu) newErrors.dateDecesLieu = "Ce champ est obligatoire.";
    if (!formData.lieuDeces2.trim()) newErrors.lieuDeces2 = "Ce champ est obligatoire.";
    if (!formData.lieuEnterrement) newErrors.lieuEnterrement = "Ce champ est obligatoire.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [delegates, setDelegates] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      // Gestion spéciale pour les checkboxes de lieu et d'enterrement
      if (name === 'lieuDeces' || name === 'lieuEnterrement') {
        setFormData({
          ...formData,
          [name]: value // Pour ces checkboxes, on utilise la valeur plutôt que checked
        });
      } else {
        // Pour les autres checkboxes (comme 'enfant')
        setFormData({
          ...formData,
          [name]: checked
        });
      }
    } else {
      // Gestion des autres types de champs
      const numericValue = numericFields.includes(name) ? parseFloat(value) || 0 : value;
      const dateValue = name === 'dateDecesLieu' ? (value !== '' ? new Date(value) : null) : numericValue;

      console.log(`handleChange - Field: ${name}, Value: ${value}, Parsed Value: ${numericValue}`); // Debugging log

      setFormData(prevState => ({
        ...prevState,
        [name]: dateValue
      }));
    }

    // Effacer les erreurs pour le champ actuel
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  useEffect(() => {
    calculateTotal();
  }, [formData]);

  const calculateTotal = () => {
    let total = 0;
    numericFields.forEach(field => {
      const value = parseFloat(formData[field]) || 0;
      console.log(`Field: ${field}, Value: ${value}`); // Debugging log
      total += value;
    });
    console.log(`Total before setFormData: ${total}`); // Debugging log
    
    if (total !== formData.total) {
        setFormData(prevState => ({ ...prevState, total: total }));
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    if (selectedFiles.length === 0) {
      alert("Veuillez ajouter au moins une pièce justificative.");
      return;
    }

    // Create FormData for file upload
    const formDataWithFiles = new FormData();
    formDataWithFiles.append('decesData', JSON.stringify(formData));
    selectedFiles.forEach((file) => {
      formDataWithFiles.append('files', file);
    });

    try {
      const response = await fetch('https://app.falconmarketing.fr:3004/deces', {
        method: 'POST',
        body: formDataWithFiles
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert('Décès ajouté avec succès!');
      // Optionally redirect or clear form
    } catch (error) {
      console.error(error);
      alert('Erreur lors de l\'ajout du décès');
    }
  };

  useEffect(() => {
    const fetchDelegates = async () => {
      try {
        const response = await axios.get('https://app.falconmarketing.fr:3004/delegates');
        setDelegates(response.data.delegateNames);
      } catch (error) {
        console.error('Error fetching delegates:', error);
      }
    };
    fetchDelegates();
  }, []);

  useEffect(() => {
    if (location.state) {
      console.log('Props passées à FicheDeces:', location.state);
      setFormData({
        ...formData,
        nomDefunt: location.state.mrName || location.state.mrsName || '',
      }, () => {
        // Calculer le total après avoir initialisé le formulaire avec les props
        calculateTotal();
      });
    }
  }, [location.state]);
  

  // Enhanced color mode values
  const bgGradient = useColorModeValue(
    'linear(to-b, blue.50, white)',
    'linear(to-b, gray.900, gray.800)'
  );
  const sectionBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const iconColor = useColorModeValue('blue.500', 'blue.300');
  const headingColor = useColorModeValue('gray.800', 'white');
  const subHeadingColor = useColorModeValue('gray.600', 'gray.400');
  const inputHoverBg = useColorModeValue('gray.50', 'gray.700');

  const containerAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <Box 
      minH="100vh"
      bgGradient={bgGradient}
      py={{ base: 4, md: 8 }}
      px={{ base: 4, md: 0 }}
      transition="all 0.2s"
    >
      <Container maxW="6xl">
        <MotionBox
          initial="hidden"
          animate="visible"
          variants={containerAnimation}
        >
          <VStack spacing={8}>
            {/* Header Section - Enhanced */}
            <MotionBox 
              w="full" 
              textAlign="center" 
              py={8}
              variants={itemAnimation}
            >
              <Heading 
                size="xl" 
                color={headingColor}
                fontWeight="bold"
                letterSpacing="tight"
                mb={3}
              >
                Fiche de Décès
              </Heading>
              <ChakraText 
                color={subHeadingColor}
                fontSize="lg"
              >
                Enregistrement des informations et dépenses
              </ChakraText>
            </MotionBox>

            {/* Main Form Content */}
            <SimpleGrid 
              columns={{ base: 1, lg: 2 }} 
              spacing={8} 
              w="full"
            >
              {/* Left Column - Basic Information */}
              <MotionBox
                variants={itemAnimation}
                as={VStack}
                spacing={6}
                bg={sectionBg}
                p={8}
                borderRadius="xl"
                boxShadow="lg"
                border="1px"
                borderColor={borderColor}
                transition="all 0.2s"
                _hover={{ boxShadow: "xl" }}
              >
                <Flex w="full" align="center" mb={4}>
                  <Icon as={FiUser} color={iconColor} boxSize={6} mr={3} />
                  <Heading size="md" color={headingColor}>Informations Principales</Heading>
                </Flex>

                <FormControl isRequired>
                  <FormLabel fontWeight="medium">Délégués</FormLabel>
                  <Select
                    name="delegue"
                    value={formData.delegue}
                    onChange={handleChange}
                    placeholder="Sélectionnez un délégué"
                    size="lg"
                    _hover={{ bg: inputHoverBg }}
                    cursor="pointer"
                  >
                    {delegates.map((delegate) => (
                      <option key={delegate} value={delegate}>
                        {delegate}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel fontWeight="medium">Décès de</FormLabel>
                  <InputGroup size="lg">
                    <Input
                      name="nomDefunt"
                      value={formData.nomDefunt}
                      onChange={handleChange}
                      placeholder="Nom du défunt"
                      _hover={{ bg: inputHoverBg }}
                    />
                  </InputGroup>
                </FormControl>

                <HStack w="full" spacing={4}>
                  <FormControl>
                    <Checkbox 
                      name="enfant" 
                      isChecked={formData.enfant} 
                      onChange={handleChange}
                      size="lg"
                      colorScheme="blue"
                      spacing={3}
                    >
                      <ChakraText fontSize="md" fontWeight="medium">Enfant</ChakraText>
                    </Checkbox>
                  </FormControl>
                </HStack>

                <FormControl isRequired>
                  <FormLabel fontWeight="medium">Date du décès</FormLabel>
                  <InputGroup size="lg">
                    <Input
                      name="dateDecesLieu"
                      value={formData.dateDecesLieu}
                      onChange={handleChange}
                      type="date"
                      _hover={{ bg: inputHoverBg }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel fontWeight="medium">Lieu du décès (détails)</FormLabel>
                  <Input
                    name="lieuDeces2"
                    value={formData.lieuDeces2}
                    onChange={handleChange}
                    size="lg"
                    placeholder="Précisez le lieu"
                    _hover={{ bg: inputHoverBg }}
                  />
                </FormControl>

                <VStack w="full" spacing={4} align="start">
                  <FormLabel fontWeight="medium">Type de lieu</FormLabel>
                  <Stack 
                    direction={{ base: "column", sm: "row" }} 
                    spacing={4}
                    w="full"
                  >
                    {["Maison", "Hôpital", "Algérie"].map((lieu) => (
                      <Checkbox
                        key={lieu}
                        name="lieuDeces"
                        value={lieu}
                        isChecked={formData.lieuDeces === lieu}
                        onChange={handleChange}
                        size="lg"
                        colorScheme="blue"
                        spacing={3}
                      >
                        <ChakraText fontSize="md">{lieu}</ChakraText>
                      </Checkbox>
                    ))}
                  </Stack>
                </VStack>

                <VStack w="full" spacing={4} align="start">
                  <FormLabel fontWeight="medium">Lieu d'enterrement</FormLabel>
                  <Stack 
                    direction={{ base: "column", sm: "row" }} 
                    spacing={4}
                    w="full"
                  >
                    {["France", "Algérie", "Autre"].map((lieu) => (
                      <Checkbox
                        key={lieu}
                        name="lieuEnterrement"
                        value={lieu}
                        isChecked={formData.lieuEnterrement === lieu}
                        onChange={handleChange}
                        size="lg"
                        colorScheme="blue"
                        spacing={3}
                      >
                        <ChakraText fontSize="md">{lieu}</ChakraText>
                      </Checkbox>
                    ))}
                  </Stack>
                </VStack>
              </MotionBox>

              {/* Right Column - Financial Information */}
              <MotionBox
                variants={itemAnimation}
                as={VStack}
                spacing={6}
                bg={sectionBg}
                p={8}
                borderRadius="xl"
                boxShadow="lg"
                border="1px"
                borderColor={borderColor}
                transition="all 0.2s"
                _hover={{ boxShadow: "xl" }}
              >
                <Flex w="full" align="center" mb={4}>
                  <Icon as={FiDollarSign} color={iconColor} boxSize={6} mr={3} />
                  <Heading size="md" color={headingColor}>Dépenses</Heading>
                </Flex>

                {[
                  { name: "funerarium", label: "Funérarium", helper: "Sur facture pompes funèbres" },
                  { name: "toilette", label: "Toilette", helper: "Sur facture pompes funèbres" },
                  { name: "pompesFunebres", label: "Pompes funèbres" },
                  { name: "billetAccompagnement", label: "Billet accompagnement" },
                  { name: "traitement", label: "Traitement", helper: "Sur facture pompes funèbres" },
                  { name: "repas", label: "Repas" },
                  { name: "concession", label: "Concession", helper: "Sur facture pompes funèbres" },
                  { name: "decesAlgerie", label: "Décès en Algérie" }
                ].map((field) => (
                  <FormControl key={field.name}>
                    <FormLabel fontWeight="medium">{field.label}</FormLabel>
                    <InputGroup size="lg">
                      <Input
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        type="number"
                        placeholder="0"
                        _hover={{ bg: inputHoverBg }}
                      />
                      <InputRightElement 
                        children="€"
                        color={iconColor}
                        fontWeight="bold"
                      />
                    </InputGroup>
                    {field.helper && (
                      <FormHelperText color={subHeadingColor}>
                        <Icon as={FiAlertCircle} mr={1} />
                        {field.helper}
                      </FormHelperText>
                    )}
                  </FormControl>
                ))}

                <Divider />

                <FormControl>
                  <FormLabel fontWeight="medium">Total</FormLabel>
                  <InputGroup size="lg">
                    <Input
                      value={formData.total}
                      isReadOnly
                      bg={useColorModeValue('blue.50', 'gray.700')}
                      fontWeight="bold"
                      fontSize="lg"
                    />
                    <InputRightElement 
                      children="€" 
                      color={iconColor}
                      fontWeight="bold"
                    />
                  </InputGroup>
                </FormControl>
              </MotionBox>
            </SimpleGrid>

            {/* Bottom Section - Additional Information */}
            <MotionBox
              variants={itemAnimation}
              w="full"
              bg={sectionBg}
              p={8}
              borderRadius="xl"
              boxShadow="lg"
              border="1px"
              borderColor={borderColor}
              transition="all 0.2s"
              _hover={{ boxShadow: "xl" }}
            >
              <VStack spacing={6} align="stretch">
                <Flex align="center" mb={4}>
                  <Icon as={FiFileText} color={iconColor} boxSize={6} mr={3} />
                  <Heading size="md" color={headingColor}>Informations Complémentaires</Heading>
                </Flex>

                <FormControl>
                  <FormLabel fontWeight="medium">Observations de la famille</FormLabel>
                  <Textarea
                    name="observations"
                    value={formData.observations}
                    onChange={handleChange}
                    size="lg"
                    rows={4}
                    placeholder="Ajoutez vos observations ici..."
                    _hover={{ bg: inputHoverBg }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontWeight="medium">Pièces justificatives</FormLabel>
                  <Box
                    borderWidth="2px"
                    borderStyle="dashed"
                    borderColor={borderColor}
                    borderRadius="xl"
                    p={6}
                    textAlign="center"
                    transition="all 0.2s"
                    _hover={{ bg: inputHoverBg }}
                  >
                    <Input
                      type="file"
                      multiple
                      onChange={(e) => setSelectedFiles(Array.from(e.target.files))}
                      accept="application/pdf,image/*"
                      size="lg"
                      opacity="0"
                      position="absolute"
                      top="0"
                      left="0"
                      w="100%"
                      h="100%"
                      cursor="pointer"
                    />
                    <Icon as={FiFileText} boxSize={8} color={iconColor} mb={3} />
                    <ChakraText fontWeight="medium">
                      Glissez vos fichiers ici ou cliquez pour sélectionner
                    </ChakraText>
                    <ChakraText fontSize="sm" color={subHeadingColor} mt={2}>
                      Formats acceptés : PDF, Images
                    </ChakraText>
                  </Box>
                  {selectedFiles.length > 0 && (
                    <VStack mt={4} align="stretch" spacing={2}>
                      {selectedFiles.map((file, index) => (
                        <Badge
                          key={index}
                          colorScheme="blue"
                          p={3}
                          borderRadius="md"
                          display="flex"
                          alignItems="center"
                        >
                          <Icon as={FiFileText} mr={2} />
                          {file.name}
                        </Badge>
                      ))}
                    </VStack>
                  )}
                </FormControl>
              </VStack>
            </MotionBox>

            {/* Submit Button */}
            <MotionButton
              onClick={handleSubmit}
              colorScheme="blue"
              size="lg"
              w={{ base: "full", md: "auto" }}
              minW="200px"
              h="60px"
              fontSize="lg"
              boxShadow="md"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.2 }}
            >
              Enregistrer le décès
            </MotionButton>
          </VStack>
        </MotionBox>
      </Container>
    </Box>
  );
};

const FichePDF = ({ formData }) => (
  <Document>
    <Page style={styles.page}>
      <Text style={styles.heading}>Fiche de Décès</Text>

      {/* Informations générales - Essayez de condenser ces informations si possible */}
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Informations générales</Text>
        <Text style={styles.label}>Délégués :</Text>
        <Text style={styles.value}>{formData.delegue}</Text>
        <Text style={styles.label}>Décès de :</Text>
        <Text style={styles.value}>{formData.nomDefunt} {formData.enfant ? '(enfant)' : ''}</Text>
        <Text style={styles.label}>Date du décès :</Text>
        <Text style={styles.value}>{new Date(formData.dateDecesLieu).toLocaleDateString()}</Text>

        <Text style={styles.label}>Lieu du décès (autre) :</Text>
        <Text style={styles.value}>{formData.lieuDeces2}</Text>
        <Text style={styles.label}>Lieu :</Text>
        <Text style={styles.value}>{formData.lieuDeces}</Text>
        <Text style={styles.label}>Enterrement :</Text>
        <Text style={styles.value}>{formData.lieuEnterrement}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Dépenses</Text>
        <View style={{ flexDirection: 'row', marginBottom: 5 }}>
          <Text style={[styles.label, { width: '70%' }]}>Description</Text>
          <Text style={[styles.label, { width: '30%', textAlign: 'right' }]}>Montant</Text>
        </View>
        {[
          { label: "Funérarium", value: formData.funerarium },
          { label: "Toilette", value: formData.toilette },
          { label: "Pompes funèbres", value: formData.pompesFunebres },
          { label: "Billet accompagnement", value: formData.billetAccompagnement },
          { label: "Traitement", value: formData.traitement },
          { label: "Repas", value: formData.repas },
          { label: "Concession", value: formData.concession },
          { label: "Décès en Algérie", value: formData.decesAlgerie },
        ].map((item, index) => (
          <View key={index} style={{ flexDirection: 'row', marginBottom: 3 }}>
            <Text style={[styles.value, { width: '70%' }]}>{item.label}</Text>
            <Text style={[styles.value, { width: '30%', textAlign: 'right' }]}>{`${item.value} €`}</Text>
          </View>
        ))}
      </View>

      {/* Totaux et observations - Essayer de garder ces informations concises */}
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Totaux</Text>
        <Text style={styles.value}>{`Total: ${formData.total} €`}</Text>
        <Text style={styles.value}>{`Montant restant pour la famille: ${formData.montantRestant} €`}</Text>
      </View>
      <Text style={styles.sectionHeading}>Observations de la famille</Text>
      <Text style={styles.value}>{formData.observations}</Text>

      {/* Footer */}
      <Text style={styles.footer}>Association El Mektoub</Text>
    </Page>
  </Document>
);

export default FicheDeces;
