import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  Divider,
  Icon,
  Box,
  Heading,
  IconButton,
  useToast,
  useBreakpointValue,
  useColorModeValue,
  SimpleGrid,
  Avatar,
  Badge,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Document, Page, Text as PdfText, View, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer';
import AdherentPDF from './AdherentPDF';
import DecesModal from './DecesModal';
import { MdDelete } from 'react-icons/md';

const formatDateToFrench = (dateString) => {
  // Si pas de date ou date invalide, retourner une chaîne vide
  if (!dateString || dateString === 'null' || dateString === 'undefined') {
    return '';
  }
  
  const date = new Date(dateString);
  
  // Vérifier si la date est valide et différente de 1970
  if (isNaN(date.getTime()) || 
      date.getFullYear() === 1970 || 
      dateString === '1970-01-01' ||
      dateString === '1970-01-01T00:00:00.000Z') {
    return '';
  }
  
  return date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

// Composants séparés pour éviter les problèmes de hooks
const InfoSection = ({ title, children }) => {
  const sectionBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const titleColor = useColorModeValue("gray.700", "white");
  
  return (
    <Box 
      bg={sectionBg}
      p={5}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      transition="all 0.2s"
    >
      <Text 
        fontSize="md" 
        fontWeight="semibold" 
        mb={4}
        color={titleColor}
        textTransform="uppercase"
        letterSpacing="wide"
      >
        {title}
      </Text>
      {children}
    </Box>
  );
};

const InfoItem = ({ label, value, isMobile }) => {
  const labelColor = useColorModeValue("gray.500", "gray.400");
  const valueColor = useColorModeValue("gray.900", "white");
  
  return (
    <Box mb={4}>
      <Text 
        fontSize="sm" 
        color={labelColor} 
        mb={1}
        fontWeight="medium"
      >
        {label}
      </Text>
      <Text 
        fontSize={isMobile ? "md" : "sm"} 
        fontWeight="medium"
        color={valueColor}
      >
        {value || "-"}
      </Text>
    </Box>
  );
};

const ChildItem = ({ child }) => {
  const itemBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.900", "white");
  const secondaryColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Box 
      p={3}
      bg={itemBg}
      borderRadius="lg"
      transition="all 0.2s"
      _hover={{ transform: "translateX(2px)" }}
    >
      <Text fontWeight="medium" color={textColor}>
        {child.name}
      </Text>
      <Text fontSize="sm" color={secondaryColor}>
        {formatDateToFrench(child.birthDate)}
      </Text>
    </Box>
  );
};

const FileItem = ({ file, onDownload, onDelete }) => {
  const itemBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.900", "white");
  const fileName = file.filePath.split('/').pop();

  return (
    <Flex
      p={3}
      bg={itemBg}
      borderRadius="lg"
      align="center"
      justify="space-between"
      transition="all 0.2s"
      _hover={{ transform: "translateY(-2px)" }}
    >
      <HStack flex={1} spacing={3}>
        <Icon as={DownloadIcon} boxSize={4} color="blue.500" />
        <Text fontSize="sm" fontWeight="medium" color={textColor} noOfLines={1}>
          {fileName}
        </Text>
      </HStack>
      <HStack spacing={2}>
        <IconButton
          aria-label="Télécharger le fichier"
          icon={<DownloadIcon />}
          colorScheme="blue"
          variant="ghost"
          size="sm"
          onClick={() => onDownload(file.filePath)}
        />
        <IconButton
          aria-label="Supprimer le fichier"
          icon={<MdDelete />}
          colorScheme="red"
          variant="ghost"
          size="sm"
          onClick={() => onDelete(file.id)}
        />
      </HStack>
    </Flex>
  );
};

const AdherentDetailsModal = ({ isOpen, onClose, adherent }) => {
  // Tous les hooks au début
  const [children, setChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [isDecesModalOpen, setIsDecesModalOpen] = useState(false);
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });
  
  // Tous les useColorModeValue au début
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.400");
  const headerBg = useColorModeValue("white", "gray.800");
  const footerBg = useColorModeValue("white", "gray.800");
  const modalBg = useColorModeValue("gray.50", "gray.900");
  const scrollbarColor = useColorModeValue("gray.300", "gray.600");

  const generatePDF = async (adherent, children, files) => {
    const blob = await pdf(
      <AdherentPDF adherent={adherent} children={children} files={files} />
    ).toBlob();
    return blob;
  };

  const handleDownload = async () => {
    const pdfBlob = await generatePDF(adherent, children, files);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `adherent_${adherent.id}.pdf`;
    link.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (adherent && adherent.id) {
        try {
          const childrenResponse = await fetch(
            `https://app.falconmarketing.fr:3004/adherents/${adherent.id}/children`
          );
          const childrenData = await childrenResponse.json();
          setChildren(childrenData.children);
          const filesResponse = await fetch(
            `https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`
          );
          const filesData = await filesResponse.json();
          setFiles(filesData.files);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setChildren([]);
          setFiles([]);
        }
      }
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, adherent]);

  const handleDeleteFile = async (fileId) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files/${fileId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du fichier");
      }

      // Rafraîchir la liste des fichiers après suppression
      const updatedFiles = files.filter(file => file.id !== fileId);
      setFiles(updatedFiles);

      toast({
        title: 'Succès',
        description: 'Le fichier a été supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Impossible de supprimer le fichier: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      if (adherent && adherent.id) {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`);
          if (!response.ok) {
            throw new Error('Erreur lors du chargement des fichiers');
          }
          const data = await response.json();
          setFiles(data.files);
        } catch (error) {
          console.error('Erreur:', error);
          toast({
            title: 'Erreur',
            description: 'Impossible de charger les fichiers',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    if (isOpen) {
      fetchFiles();
    }
  }, [isOpen, adherent]);

  const handleFileDownload = (filePath) => {
    // Construire l'URL complète pour le téléchargement
    const fileUrl = `https://app.falconmarketing.fr:3004/${filePath}`;
    window.open(fileUrl, '_blank');
  };

  if (!adherent) return null;

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size={isMobile ? "full" : "6xl"}
      motionPreset={isMobile ? "slideInBottom" : "scale"}
    >
      <ModalOverlay backdropFilter="blur(5px)" bg="blackAlpha.300" />
      <ModalContent 
        margin={isMobile ? 0 : "3.75rem auto"}
        bg={modalBg}
        borderRadius={isMobile ? "none" : "2xl"}
        overflow="hidden"
      >
        <ModalHeader 
          bg={headerBg}
          borderBottom="1px"
          borderColor={borderColor}
          p={6}
        >
          <Flex justify="space-between" align="center">
            <HStack spacing={4}>
              <Avatar 
                name={adherent.mrName} 
                size={isMobile ? "md" : "lg"}
                bg="teal.500"
                color="white"
              />
              <Box>
                <Heading size={isMobile ? "md" : "lg"} mb={1}>
                  {adherent.mrName}
                </Heading>
                <HStack spacing={4}>
                  <Text color={secondaryTextColor} fontSize="sm">
                    #{adherent.id}
                  </Text>
                  <Badge
                    colorScheme={
                      adherent.contributionStatus === "À jour" ? "green" :
                      adherent.contributionStatus === "En retard" ? "orange" :
                      adherent.contributionStatus === "Radié" ? "red" : "gray"
                    }
                    variant="subtle"
                    px={2}
                    py={1}
                    borderRadius="md"
                  >
                    {adherent.contributionStatus}
                  </Badge>
                </HStack>
              </Box>
            </HStack>
          </Flex>
        </ModalHeader>

        <ModalCloseButton 
          size={isMobile ? "lg" : "md"}
          top={6}
          right={6}
        />

        <ModalBody 
          p={6} 
          maxH="calc(100vh - 200px)" 
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: scrollbarColor,
              borderRadius: '24px',
            },
          }}
        >
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
            <InfoSection title="Informations personnelles">
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
                <InfoItem label="Nom et Prénom (M.)" value={adherent.mrName} isMobile={isMobile} />
                <InfoItem label="Nom et Prénom (Mme)" value={adherent.mrsName} isMobile={isMobile} />
                <InfoItem label="Date de naissance" value={formatDateToFrench(adherent.birthDate)} isMobile={isMobile} />
                <InfoItem label="Date de naissance (conjoint)" value={formatDateToFrench(adherent.birthDateLine2)} isMobile={isMobile} />
                <InfoItem label="Nationalité" value={adherent.nationality} isMobile={isMobile} />
                <InfoItem label="Situation familiale" value={getFamilyStatusLabel(adherent.maritalStatus)} isMobile={isMobile} />
              </SimpleGrid>
            </InfoSection>

            <InfoSection title="Coordonnées">
              <VStack align="stretch" spacing={4}>
                <InfoItem label="Adresse" value={adherent.address} isMobile={isMobile} />
                <InfoItem label="Téléphone" value={adherent.phone} isMobile={isMobile} />
                <InfoItem label="Email" value={adherent.email} isMobile={isMobile} />
              </VStack>
            </InfoSection>

            <InfoSection title="Informations administratives">
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
                <InfoItem label="N° de sécurité sociale" value={adherent.socialSecurityNumber} isMobile={isMobile} />
                <InfoItem label="N° de sécurité sociale (conjoint)" value={adherent.socialSecurityNumberLine2} isMobile={isMobile} />
                <InfoItem label="Lieu d'inhumation" value={adherent.burialSite} isMobile={isMobile} />
                <InfoItem label="Nom du délégué" value={adherent.delegateName} isMobile={isMobile} />
              </SimpleGrid>
            </InfoSection>

            {children.length > 0 && (
              <InfoSection title="Enfants">
                <VStack align="stretch" spacing={2}>
                  {children.map((child) => (
                    <ChildItem 
                      key={child.id} 
                      child={child} 
                    />
                  ))}
                </VStack>
              </InfoSection>
            )}
          </SimpleGrid>

          {files.length > 0 && (
            <Box mt={6}>
              <InfoSection title="Pièces justificatives">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  {files.map((file) => (
                    <FileItem
                      key={file.id}
                      file={file}
                      onDownload={handleFileDownload}
                      onDelete={handleDeleteFile}
                    />
                  ))}
                </SimpleGrid>
              </InfoSection>
            </Box>
          )}
        </ModalBody>

        <ModalFooter 
          borderTop="1px" 
          borderColor={borderColor}
          p={4}
          bg={footerBg}
        >
          <HStack spacing={3} width={isMobile ? "full" : "auto"}>
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="blue"
              size={isMobile ? "lg" : "md"}
              flex={isMobile ? 1 : "auto"}
            >
              Télécharger PDF
            </Button>
            <Button 
              onClick={onClose}
              size={isMobile ? "lg" : "md"}
              flex={isMobile ? 1 : "auto"}
              variant="ghost"
            >
              Fermer
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// Fonction utilitaire pour convertir le statut familial en libellé
const getFamilyStatusLabel = (status) => {
  const statusMap = {
    'married': 'Marié',
    'single': 'Célibataire',
    'divorcedWithChildren': 'Divorcé avec enfants',
    'divorcedWithoutChildren': 'Divorcé sans enfants',
    'widowed': 'Veuve/Veuf'
  };
  return statusMap[status] || status;
};

// Composant séparé pour le style de la scrollbar
const StyledModalBody = ({ children, scrollbarColor, ...props }) => (
  <ModalBody
    {...props}
    css={{
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: scrollbarColor,
        borderRadius: '24px',
      },
    }}
  >
    {children}
  </ModalBody>
);

export default AdherentDetailsModal;