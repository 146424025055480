import React, { useState } from 'react';
import {
  VStack,
  Text,
  Button,
  HStack,
  IconButton,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiDownload, FiTrash2 } from 'react-icons/fi';
import axios from 'axios';

const DecesFilesList = ({ files, onFileDeleted }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const cancelRef = React.useRef();
  const toast = useToast();

  // Couleurs pour le mode sombre/clair
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');
  const modalBg = useColorModeValue('white', 'gray.800');

  const handleDownload = async (fileId, filename) => {
    try {
      const response = await axios.get(`https://app.falconmarketing.fr:3004/deces/files/${fileId}`, {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de télécharger le fichier.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!fileToDelete) return;

    try {
      await axios.delete(`https://app.falconmarketing.fr:3004/deces/files/${fileToDelete.id}`);
      
      toast({
        title: 'Succès',
        description: 'Fichier supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Notifier le composant parent de la suppression
      if (onFileDeleted) {
        onFileDeleted(fileToDelete.id);
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le fichier.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
      setFileToDelete(null);
    }
  };

  if (!files || files.length === 0) {
    return <Text color={secondaryTextColor}>Aucune pièce justificative</Text>;
  }

  return (
    <>
      <VStack align="stretch" spacing={4}>
        <Text fontWeight="bold" color={textColor}>Pièces justificatives :</Text>
        {files.map((file) => (
          <HStack key={file.id} justify="space-between">
            <Text color={secondaryTextColor}>{file.filename}</Text>
            <HStack spacing={2}>
              <IconButton
                icon={<FiDownload />}
                colorScheme="blue"
                size="sm"
                onClick={() => handleDownload(file.id, file.filename)}
                aria-label="Télécharger"
              />
              <IconButton
                icon={<FiTrash2 />}
                colorScheme="red"
                size="sm"
                onClick={() => handleDeleteClick(file)}
                aria-label="Supprimer"
              />
            </HStack>
          </HStack>
        ))}
      </VStack>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg={modalBg}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color={textColor}>
              Confirmer la suppression
            </AlertDialogHeader>
            <AlertDialogBody color={textColor}>
              Êtes-vous sûr de vouloir supprimer cette pièce justificative ? Cette action est irréversible.
              {fileToDelete && (
                <Text mt={2} color={secondaryTextColor}>
                  Fichier : {fileToDelete.filename}
                </Text>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteDialogOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DecesFilesList; 