import { mode } from "@chakra-ui/theme-tools";
export const inputStyles = {
  components: {
    Input: {
      baseStyle: {
        field: {
          fontWeight: 400,
          borderRadius: "6px",
        },
      },
      variants: {
        main: (props) => ({
          field: {
            bg: mode("transparent", "transparent")(props),
            border: "1px solid",
            color: mode("gray.900", "#FAFAFA")(props),
            borderColor: mode("gray.200", "#27272A")(props),
            borderRadius: "6px",
            fontSize: "sm",
            p: "8px 12px",
            _placeholder: { color: mode("gray.400", "#71717A")(props) },
            _hover: {
              borderColor: mode("gray.300", "#3F3F46")(props),
            },
            _focus: {
              borderColor: "#22C55E",
              boxShadow: "0 0 0 1px #22C55E",
            },
            _disabled: {
              bg: mode("gray.50", "#18181B")(props),
              borderColor: mode("gray.200", "#27272A")(props),
              cursor: "not-allowed",
              _hover: {
                borderColor: mode("gray.200", "#27272A")(props),
              },
            },
          },
        }),
        auth: (props) => ({
          field: {
            bg: mode("gray.50", "#18181B")(props),
            border: "1px solid",
            borderColor: mode("gray.200", "#27272A")(props),
            borderRadius: "6px",
            _placeholder: { color: mode("gray.400", "#71717A")(props) },
            _hover: {
              borderColor: mode("gray.300", "#3F3F46")(props),
            },
            _focus: {
              borderColor: "#22C55E",
              boxShadow: "0 0 0 1px #22C55E",
            },
          },
        }),
        search: (props) => ({
          field: {
            border: "none",
            py: "11px",
            borderRadius: "inherit",
            _placeholder: { color: mode("gray.400", "#71717A")(props) },
            _hover: {
              bg: mode("gray.50", "#27272A")(props),
            },
            _focus: {
              bg: mode("gray.50", "#27272A")(props),
              borderColor: "transparent",
              boxShadow: "none",
            },
          },
        }),
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },
      variants: {
        main: (props) => ({
          field: {
            bg: mode("transparent", "transparent")(props),
            border: "1px solid",
            borderColor: mode("gray.200", "#27272A")(props),
            borderRadius: "6px",
            _placeholder: { color: mode("gray.400", "#71717A")(props) },
            _hover: {
              borderColor: mode("gray.300", "#3F3F46")(props),
            },
            _focus: {
              borderColor: "#22C55E",
              boxShadow: "0 0 0 1px #22C55E",
            },
          },
        }),
      },
    },
    Select: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },
      variants: {
        main: (props) => ({
          field: {
            bg: mode("transparent", "transparent")(props),
            border: "1px solid",
            color: mode("gray.900", "#FAFAFA")(props),
            borderColor: mode("gray.200", "#27272A")(props),
            borderRadius: "6px",
            _placeholder: { color: mode("gray.400", "#71717A")(props) },
            _hover: {
              borderColor: mode("gray.300", "#3F3F46")(props),
            },
            _focus: {
              borderColor: "#22C55E",
              boxShadow: "0 0 0 1px #22C55E",
            },
          },
        }),
      },
    },
  },
};
