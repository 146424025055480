// Chakra Imports
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode,
  } from '@chakra-ui/react';
  // Custom Components
  import { ItemContent } from 'components/menu/ItemContent';
  import { SearchBar } from 'components/navbar/searchBar/SearchBar';
  import { SidebarResponsive } from 'components/sidebar/Sidebar';
  import PropTypes from 'prop-types';
  import React from 'react';
  // Assets
  import navImage from 'assets/img/layout/Navbar.png';
  import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
  import { IoMdMoon, IoMdSunny } from 'react-icons/io';
  import { FaEthereum } from 'react-icons/fa';
  import routes from 'routes.js';
  // Contexts
  import { AuthContext } from 'contexts/AuthContext';
  
  export default function HeaderLinks(props) {
    // Auth context function
    const { signOut } = React.useContext(AuthContext);
    const { secondary } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    // Chakra Color Mode
    const navbarIcon = useColorModeValue('gray.400', '#A1A1AA');
    let menuBg = useColorModeValue('white', '#18181B');
    const textColor = useColorModeValue('gray.700', '#FAFAFA');
    const borderColor = useColorModeValue('gray.200', '#27272A');
    const shadow = useColorModeValue(
      '0 1px 3px rgba(0, 0, 0, 0.1)',
      '0 1px 3px rgba(0, 0, 0, 0.2)'
    );
    return (
      <Flex
        w={{ sm: '100%', md: 'auto' }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
        p="16px"
        borderRadius="8px"
        boxShadow={shadow}
        borderWidth="1px"
        borderColor={borderColor}
      >
        <SearchBar
          mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
          me="16px"
          borderRadius="6px"
        />
 
        <SidebarResponsive routes={routes} />
        
  
        
  
        <Button
          variant="ghost"
          bg="transparent"
          p="8px"
          minW="unset"
          minH="unset"
          h="auto"
          w="auto"
          onClick={toggleColorMode}
          _hover={{ bg: useColorModeValue('gray.100', '#27272A') }}
        >
          <Icon
            h="18px"
            w="18px"
            color={navbarIcon}
            as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
          />
        </Button>
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              name=""
              bg={useColorModeValue('brand.500', 'brand.400')}
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="16px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="600"
                color={textColor}
              >
                👋&nbsp; Bienvenue !
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
            
             
              <MenuItem
                _hover={{ bg: useColorModeValue('gray.100', 'whiteAlpha.200') }}
                _focus={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                borderRadius="8px"
                px="14px"
                onClick={signOut}
              >
                <Text fontSize="sm" color="red.500" fontWeight="500">Se déconnecter</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    );
  }
  
  HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
  };