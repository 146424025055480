import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";
import { mode } from "@chakra-ui/theme-tools";

// Configuration du mode sombre
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// Styles de base pour le mode sombre
const styles = {
  global: (props) => ({
    body: {
      bg: mode("white", "gray.800")(props),
      color: mode("gray.800", "whiteAlpha.900")(props),
    },
  }),
};

// Couleurs personnalisées
const colors = {
  brand: {
    100: "#D4E6CD",
    200: "#7FB77E",
    300: "#5B9C55",
    400: "#8CC28A",
    500: "#52A852",
    600: "#3D7C3D",
    700: "#1E4D1E",
    800: "#66B566",
    900: "#0C2D0C",
  },
  darkMode: {
    background: "#09090B",
    surface: "#18181B",
    card: "#18181B",
    border: "#27272A",
    text: "#FAFAFA",
    textSecondary: "#A1A1AA",
    hover: "#27272A",
    accent: "#22C55E",
  },
};

// Composants avec support du mode sombre
const components = {
  Card: {
    baseStyle: (props) => ({
      bg: mode("white", "darkMode.surface")(props),
      boxShadow: mode(
        "0 1px 3px rgba(0, 0, 0, 0.1)",
        "0 1px 3px rgba(0, 0, 0, 0.3)"
      )(props),
    }),
  },
  Button: {
    ...buttonStyles,
    baseStyle: (props) => ({
      _hover: {
        transform: "translateY(-1px)",
        boxShadow: mode(
          "0 4px 6px rgba(0, 0, 0, 0.1)",
          "0 4px 6px rgba(0, 0, 0, 0.3)"
        )(props),
      },
    }),
  },
  Input: {
    ...inputStyles,
    baseStyle: (props) => ({
      field: {
        bg: mode("white", "darkMode.surface")(props),
        borderColor: mode("gray.200", "darkMode.border")(props),
        _hover: {
          borderColor: mode("gray.300", "darkMode.hover")(props),
        },
        _focus: {
          borderColor: "brand.500",
          boxShadow: `0 0 0 1px ${colors.brand[500]}`,
        },
      },
    }),
  },
  Textarea: {
    ...textareaStyles,
    baseStyle: (props) => ({
      bg: mode("white", "darkMode.surface")(props),
      borderColor: mode("gray.200", "darkMode.border")(props),
      _hover: {
        borderColor: mode("gray.300", "darkMode.hover")(props),
      },
      _focus: {
        borderColor: "brand.500",
        boxShadow: `0 0 0 1px ${colors.brand[500]}`,
      },
    }),
  },
  Table: {
    baseStyle: (props) => ({
      th: {
        bg: mode("gray.50", "darkMode.surface")(props),
        color: mode("gray.600", "darkMode.text")(props),
        borderBottom: "1px",
        borderColor: mode("gray.200", "darkMode.border")(props),
      },
      td: {
        borderBottom: "1px",
        borderColor: mode("gray.200", "darkMode.border")(props),
      },
      tr: {
        _hover: {
          bg: mode("gray.50", "darkMode.hover")(props),
        },
      },
    }),
  },
  Modal: {
    baseStyle: (props) => ({
      dialog: {
        bg: mode("white", "darkMode.surface")(props),
        boxShadow: mode(
          "0 4px 6px rgba(0, 0, 0, 0.1)",
          "0 4px 6px rgba(0, 0, 0, 0.3)"
        )(props),
      },
      header: {
        borderBottom: "1px",
        borderColor: mode("gray.200", "darkMode.border")(props),
      },
      footer: {
        borderTop: "1px",
        borderColor: mode("gray.200", "darkMode.border")(props),
      },
    }),
  },
  Menu: {
    baseStyle: (props) => ({
      list: {
        bg: mode("white", "darkMode.surface")(props),
        boxShadow: mode(
          "0 4px 6px rgba(0, 0, 0, 0.1)",
          "0 4px 6px rgba(0, 0, 0, 0.3)"
        )(props),
        borderColor: mode("gray.200", "darkMode.border")(props),
      },
      item: {
        _hover: {
          bg: mode("gray.100", "darkMode.hover")(props),
        },
      },
    }),
  },
};

// Export du thème
export default extendTheme(
  {
    config,
    styles,
    colors,
    components,
    breakpoints,
  },
  globalStyles,
  badgeStyles,
  buttonStyles,
  linkStyles,
  progressStyles,
  sliderStyles,
  inputStyles,
  textareaStyles,
  switchStyles,
  CardComponent
);
