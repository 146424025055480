import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    brand: {
      100: "#E6F0E6", // Lighter green
      200: "#C2DCC2", // Light green
      300: "#9EC89E", // Medium light green
      400: "#7AB47A", // Medium green
      500: "#52A852", // Primary green
      600: "#408040", // Medium dark green
      700: "#2E5C2E", // Dark green
      800: "#1C381C", // Darker green
      900: "#0A140A", // Darkest green
    },
    brandScheme: {
      100: "#E6F0E6",
      200: "#C2DCC2",
      300: "#9EC89E",
      400: "#7AB47A",
      500: "#52A852",
      600: "#408040",
      700: "#2E5C2E",
      800: "#1C381C",
      900: "#0A140A",
    },
    secondaryGray: {
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
    },
    darkMode: {
      background: "#1A202C",
      surface: "#2D3748",
      primary: "#52A852",
      text: "#FFFFFF",
      textSecondary: "#A0AEC0",
      border: "#4A5568",
      hover: "#4A5568",
      inputBg: "#2D3748",
      cardBg: "#2D3748",
      modalBg: "#2D3748",
      tableBg: "#2D3748",
      tableHeaderBg: "#4A5568",
      tableHoverBg: "#4A5568",
    }
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("gray.50", "navy.900")(props),
        fontFamily: "'Inter', sans-serif",
        letterSpacing: "-0.025em",
      },
      input: {
        color: mode("gray.700", "white")(props),
      },
      html: {
        fontFamily: "'Inter', sans-serif",
      },
      '*': {
        borderColor: mode("gray.200", "whiteAlpha.200")(props),
        borderWidth: "0px",
      },
      "::placeholder": {
        color: mode("gray.500", "whiteAlpha.400")(props),
      }
    }),
  },
};