import React from "react";
import { useContext } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { AuthContext } from "contexts/AuthContext";

// Assets
import { IoMenuOutline } from "react-icons/io5";

function Sidebar(props) {
  const { user } = useContext(AuthContext); // Accès aux informations de l'utilisateur
  const { routes } = props;

  // Filtrer les routes en prenant en compte `requiresAdmin` et `hideLink`
  const filteredRoutes = routes.filter(route => {
    const isAdminRoute = route.requiresAdmin ? user?.isAdmin : true;
    return isAdminRoute && !route.hideLink;
  });


  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "0 1px 3px rgba(0, 0, 0, 0.1)",
    "0 1px 3px rgba(0, 0, 0, 0.2)"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "#18181B");
  let sidebarMargins = "16px";
  let borderRadius = "8px";

  // SIDEBAR
  return (
    <Box display={{ sm: "none", xl: "block" }} position='fixed' minH='100%' left="0" top="0" w="290px" bg={useColorModeValue("white", "#09090B")}>
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w='250px'
        maxW='250px'
        ms={{
          sm: "16px",
        }}
        my={{
          sm: "16px",
        }}
        h='calc(100vh - 32px)'
        m={sidebarMargins}
        borderRadius={borderRadius}
        minH='100%'
        overflowX='hidden'
        boxShadow={shadow}
        borderColor={useColorModeValue("gray.200", "#27272A")}
        borderWidth="1px"
        position="relative"
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          width: "16px",
          borderRadius: "0 8px 8px 0",
          background: "inherit",
          borderRight: "1px solid",
          borderColor: "inherit",
        }}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
            <Content routes={filteredRoutes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  
  let sidebarBackgroundColor = useColorModeValue("white", "#18181B");
  let menuColor = useColorModeValue("gray.400", "#FAFAFA");
  let shadow = useColorModeValue(
    "0 1px 3px rgba(0, 0, 0, 0.1)",
    "0 1px 3px rgba(0, 0, 0, 0.2)"
  );
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  
  const { user } = useContext(AuthContext);
  const { routes } = props;
  
  const filteredRoutes = routes.filter(route => {
    const isAdminRoute = route.requiresAdmin ? user?.isAdmin : true;
    return isAdminRoute && !route.hideLink;
  });

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
      <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}>
        <DrawerOverlay bg="blackAlpha.400" backdropFilter="blur(10px)" />
        <DrawerContent
          w='250px'
          maxW='250px'
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          borderRadius="8px"
          bg={sidebarBackgroundColor}
          boxShadow={shadow}
          borderWidth="1px"
          borderColor={useColorModeValue("gray.200", "#27272A")}>
          <DrawerCloseButton
            zIndex='3'
            onClick={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
            color={menuColor}
          />
          <DrawerBody maxW='250px' px='1rem'>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}>
               <Content routes={filteredRoutes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
