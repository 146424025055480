import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "6px",
        transition: "all 0.2s ease",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        outline: (props) => ({
          borderRadius: "6px",
          border: "1px solid",
          borderColor: mode("gray.200", "#27272A")(props),
          color: mode("gray.800", "#FAFAFA")(props),
          _hover: {
            bg: mode("gray.50", "#27272A")(props),
          },
        }),
        brand: (props) => ({
          bg: "#22C55E",
          color: "white",
          _focus: {
            bg: "#22C55E",
          },
          _active: {
            bg: "#16A34A",
          },
          _hover: {
            bg: "#16A34A",
            _disabled: {
              bg: "#22C55E",
            },
          },
        }),
        darkBrand: (props) => ({
          bg: mode("#18181B", "#27272A")(props),
          color: "white",
          _focus: {
            bg: mode("#27272A", "#3F3F46")(props),
          },
          _active: {
            bg: mode("#3F3F46", "#52525B")(props),
          },
          _hover: {
            bg: mode("#27272A", "#3F3F46")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F9FAFB", "#27272A")(props),
          color: mode("#18181B", "#FAFAFA")(props),
          _focus: {
            bg: mode("#F3F4F6", "#3F3F46")(props),
          },
          _active: {
            bg: mode("#E5E7EB", "#52525B")(props),
          },
          _hover: {
            bg: mode("#F3F4F6", "#3F3F46")(props),
          },
        }),
        light: (props) => ({
          bg: mode("gray.50", "#27272A")(props),
          color: mode("gray.800", "#FAFAFA")(props),
          _hover: {
            bg: mode("gray.100", "#3F3F46")(props),
          },
          _active: {
            bg: mode("gray.200", "#52525B")(props),
          },
        }),
      },
    },
  },
};
